import "react-native-gesture-handler";
import React, { useState, useContext, lazy, Suspense } from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Button, Icon, Text } from "react-native-elements";

// Importa tus pantallas aquí
import FormScreen from "@screens/FormScreen";
import ListadoFormulariosScreen from "@screens/RolScreen/Supervisor/ListadoFormulariosScreen";
import ConfiguracionScreen from "@screens/ConfigurationScreen";
import { AuthContext } from "@context/AuthContext";
import ProfileScreen from "@screens/ProfileScreen";
import Ionicons from "react-native-vector-icons/Ionicons";
import colors from "@styles/colors";
import MyButton from "@components/MyButton";
import AsesoresScreen from "@screens/RolScreen/Supervisor/AsesoresScreen";
import DetailUserScreen from "@screens/DetailUserScreen";
import { StatusBar } from "expo-status-bar";
import AdministrarFormularioScreen from "@screens/AdministrarFormularioScreen";
import DetalleCliente from "@screens/DetalleClienteScreen";

const Tab = createBottomTabNavigator();
const TabNavigation = () => {
  const { logout, userInfo } = useContext(AuthContext);

  return (
    <Tab.Navigator>
      <Tab.Screen
        name="Formulario"
        component={AdministrarFormularioScreen}
        options={({ navigation }) => ({
          title: "Lista de Clientes",
          headerTitleAlign: "center",
          headerTitleStyle: { fontWeight: "bold" },
          headerTintColor: colors.brand,
          tabBarLabel: "Cliente",
          tabBarLabelStyle: { fontSize: 16 },
          headerRightContainerStyle: { padding: 8 },
          tabBarIcon: ({ size, focused }) => {
            return (
              <Ionicons
                name={focused ? "document-text" : "document-text-outline"}
                size={size}
                color={colors.brand}
              />
            );
          },
          tabBarLabelStyle: { color: colors.brand },
          headerRight: () => (
            <MyButton
              title="Agregar"
              type="clean"
              isIcon
              iconLibrary="material"
              iconName="add"
              pressButton={() => navigation.navigate("AgregarFormulario")}
            />
          ),
          headerLeft: () => (
            <MyButton
              title="Salir"
              type="clear"
              isIcon
              iconLibrary="material"
              iconName="logout"
              pressButton={() => logout()}
            />
          ),
        })}
      />
      <Tab.Screen
        name="DetalleCliente"
        component={DetalleCliente}
        options={({ navigation }) => ({
          tabBarButton: () => null,
          title: "Detalle del Cliente",
          headerTitleAlign: "center",
          headerTitleStyle: { fontWeight: "bold" },
          headerTintColor: colors.brand,
          headerLeft: () => (
            <MyButton
              type="clear"
              isIcon
              iconLibrary="material"
              iconName="arrow-back"
              pressButton={() => navigation.navigate("Formulario")}
            />
          ),
        })}
      />
      <Tab.Screen
        name="AgregarFormulario"
        component={FormScreen}
        options={({ navigation }) => ({
          tabBarButton: () => null,
          title: "Registrar Cliente",
          headerTitleAlign: "center",
          headerTitleStyle: { fontWeight: "bold" },
          headerTintColor: colors.brand,
          headerLeft: () => (
            <MyButton
              type="clear"
              isIcon
              iconLibrary="material"
              iconName="arrow-back"
              pressButton={() => navigation.navigate("Formulario")}
            />
          ),
        })}
      />
      {/*userInfo.role !== 'customer' ? (
				<Tab.Screen
					name='ListadoFormulario'
					component={ListadoFormulariosScreen}
				/>
			) : (
				<></>
			)*/}

      {/*<Tab.Screen name='Configuracion' component={ConfiguracionScreen} />*/}
      {/*userInfo.idRol === 2 ? (
        <Tab.Screen
          name="Asesores"
          component={AsesoresScreen}
          options={{
            title: 'Listado de Asesores',
            headerTitleAlign: 'center',
            headerTitleStyle: { fontWeight: 'bold' },
            tabBarLabel: 'Asesores',
            tabBarLabelStyle: { fontSize: 16 },
            tabBarIcon: ({ size, focused }) => {
              return (
                <Ionicons
                  name={focused ? 'people' : 'people-outline'}
                  size={size}
                  color={colors.brand}
                />
              );
            },
            tabBarLabelStyle: { color: colors.brand },
            headerRight: () => (
              <MyButton
                title="Salir"
                type="clear"
                isIcon
                iconLibrary="material"
                iconName="logout"
                pressButton={() => logout()}
              />
            ),
          }}
        />
      ) : (
        <></>
      )*/}

      <Tab.Screen
        name="DetailUser"
        component={DetailUserScreen}
        options={({ navigation }) => ({
          tabBarButton: () => null,
          title: "Información del asesor",
          headerTitleAlign: "center",
          headerTitleStyle: { fontWeight: "bold" },
          headerTintColor: colors.brand,
          headerLeft: () => (
            <MyButton
              type="clear"
              isIcon
              iconLibrary="material"
              iconName="arrow-back"
              pressButton={() => navigation.navigate("Asesores")}
            />
          ),
        })}
      />
      <Tab.Screen
        name="Profile"
        component={ProfileScreen}
        options={{
          title: "Perfil",
          headerTitleAlign: "center",
          headerTitleStyle: { fontWeight: "bold" },
          tabBarLabel: "Perfil",
          tabBarLabelStyle: { fontSize: 16 },
          tabBarIcon: ({ size, focused }) => {
            return (
              <Ionicons
                name={focused ? "person" : "person-outline"}
                size={size}
                color={colors.brand}
              />
            );
          },
          tabBarLabelStyle: { color: colors.brand },
          headerRight: () => (
            <MyButton
              title="Salir"
              type="clear"
              isIcon
              iconLibrary="material"
              iconName="logout"
              pressButton={() => logout()}
            />
          ),
        }}
      />
    </Tab.Navigator>
  );
};

export default TabNavigation;
