import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import MyTextInput from '@components/MyTextInput';
import { Text } from 'react-native-elements';
import color from '@styles/colors';
import MyDropdown from '@components/MyDropdown';
import useInputValue from '@hooks/useInputValue';

const DatosProductos = ({
  onGuardarDatos,
  isCategoriaError,
  isTipificacionError,
  isProductoError,
  isPerfilError,
  isMAFError,
  resetState,
}) => {
  const [categoria, setCategoria] = useState('');
  const [tipificacion, setTipificacion] = useState('');
  const [producto, setProducto] = useState('1');
  const [perfil, setPerfil] = useState('');
  const mafInput = useInputValue('');

  const dataProducto = {
    tipificacion: categoria,
    conceptoTipificacion: tipificacion,
    categoriaProducto: producto,
    perfilCliente: perfil,
    maf: mafInput.value,
  };

  const [param, setParam] = useState(1);
  const [disabled, setDisabled] = useState(true);
  const handleCategoriaChange = (selectedValue) => {
    setCategoria(selectedValue);
    setDisabled(false);
    setParam(selectedValue);
  };

  useEffect(() => {
    onGuardarDatos(dataProducto);
    //console.log(dataProducto);
  }, [categoria, tipificacion, producto, perfil, mafInput.value]);

  const resetStateFunction = () => {
    setCategoria('');
    setTipificacion('');
    setProducto('');
    setPerfil('');
    setParam(0);
    mafInput.handleChange('');
  };

  useEffect(() => {
    resetStateFunction();
  }, [resetState]);

  return (
    <View>
      <Text
        h4
        h4Style={{
          backgroundColor: color.brand,
          textAlign: 'center',
          padding: 12,
          color: color.white,
          fontSize: 18,
          marginBottom: 24,
        }}
      >
        Datos del Tipo de Producto
      </Text>
      <View
        style={{
          flexDirection: 'row',
          gap: 16,
          paddingLeft: 16,
          paddingRight: 16,
          marginBottom: 16,
        }}
      >
        <View style={{ flexGrow: 1 }}>
          <MyDropdown
            title="Tipificación *"
            placeholder="Selecciona"
            endpoint="/categoria/tipificacion"
            icon="document"
            onValueChange={handleCategoriaChange}
            isVisibleSearch={false}
            isError={isCategoriaError}
            errorMessage={
              isCategoriaError ? 'Vuelva a seleccionar la categoria' : ''
            }
          />
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          gap: 16,
          paddingLeft: 16,
          paddingRight: 16,
          marginBottom: 16,
        }}
      >
        <View style={{ flexGrow: 1 }}>
          <MyDropdown
            title="Estado tipificación *"
            placeholder="Selecciona..."
            endpoint={`/categoria/tipificacion/${param}`}
            icon="document-text"
            isDisabled={disabled}
            isVisibleSearch={true}
            onValueChange={(data) => setTipificacion(data)}
            isError={isTipificacionError}
            errorMessage={
              isTipificacionError ? 'Selecciona la tipificación' : ''
            }
          />
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          gap: 16,
          paddingLeft: 16,
          paddingRight: 16,
          marginBottom: 16,
        }}
      >
        <View style={{ flexGrow: 1 }}>
          <MyDropdown
            title="Categoria de Producto *"
            placeholder="Selecciona..."
            endpoint="/categoria/productos"
            icon="card"
            isVisibleSearch={false}
            onValueChange={(data) => setProducto(data)}
            isError={isProductoError}
            errorMessage={
              isProductoError ? 'Vuelva a seleccionar el producto' : ''
            }
          />
        </View>
      </View>

      {dataProducto.tipificacion != 2 ? (
        <View
          style={{
            flexDirection: 'row',
            gap: 16,
            paddingLeft: 16,
            paddingRight: 16,
            marginBottom: 16,
            flexWrap: 'wrap',
          }}
        >
          <View style={{ flexGrow: 1 }}>
            <MyDropdown
              title="Perfil *"
              placeholder="Selecciona..."
              endpoint="/categoria/perfil_cliente"
              icon="person"
              isVisibleSearch={true}
              onValueChange={(data) => setPerfil(data)}
              isError={isPerfilError}
              errorMessage={
                isPerfilError ? 'Vuelva a seleccionar el perfil' : ''
              }
            />
          </View>
          <View style={{ flexGrow: 1 }}>
            <MyTextInput
              keyboardType="numeric"
              //maxLength={8}
              label="MAF (S/.) *"
              placeholder="100.00"
              isIcon
              isLeftIcon
              iconLeftLibrary="material"
              iconLeftName="payments"
              isError={isMAFError}
              errorMessage={isMAFError ? 'Ingresa un monto válido' : ''}
              {...mafInput}
            />
          </View>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

export default DatosProductos;
