import "react-native-gesture-handler";
import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

// Importa tus pantallas aquí
import LoginScreen from "@screens/LoginScreen";
import ForgotPasswordScreen from "@screens/ForgotPasswordScreen";
import color from "@styles/colors";

const Stack = createStackNavigator();

const StackNavigation = () => {
  return (
    <Stack.Navigator initialRouteName="Login">
      <Stack.Screen
        name="Login"
        options={{ headerShown: false }}
        component={LoginScreen}
      />
      <Stack.Screen
        name="ForgetPassword"
        component={ForgotPasswordScreen}
        options={{
          title: "Recuperar contraseña",
          headerTitleAlign: "center",
          headerTitleStyle: { fontWeight: "bold" },
          headerTintColor: color.brand,
        }}
      />
    </Stack.Navigator>
  );
};

export default StackNavigation;
