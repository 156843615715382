import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import MyTextInput from '@components/MyTextInput';
import { Text } from 'react-native-elements';
import color from '@styles/colors';
import MyDropdown from '@components/MyDropdown';
import DateTimePicker from '@react-native-community/datetimepicker';
import { TouchableOpacity } from 'react-native';
import useInputValue from '@hooks/useInputValue';
import { invertirFormatoFecha } from '@utils/convertirFormatoFechaHora';

const DatosProspeccion = ({
  onGuardarDatos,
  isModalidadCreditoError,
  isFechaVisitaError,
  isHoraVisitaError,
  resetState,
}) => {
  const [date, setDate] = useState(new Date());
  const currentDate = new Date().toLocaleString('es-PE', {
    timeZone: 'America/Lima',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const [modalidadCredito, setModalidadCredito] = useState('');
  const [horaVisita, setHoraVisita] = useState('');
  const [registro, setRegistro] = useState(currentDate);
  const [showPicker, setShowPicker] = useState(false);

  const onChangeDate = (event, selectedDate) => {
    setShowPicker(false); // Oculta el picker al seleccionar una fecha

    if (selectedDate) {
      const currentDate = selectedDate;
      const day = String(currentDate.getDate()).padStart(2, '0');
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const year = currentDate.getFullYear();

      //const formattedDate = `${day}/${month}/${year}`;
      const formattedDate = `${year}-${month}-${day}`;

      setDate(currentDate); // Actualiza el estado de la fecha seleccionada
      setRegistro(formattedDate); // Actualiza el estado para mostrar en el botón
    }
  };
  const visitaInput = useInputValue('');

  const dataProspeccion = {
    modalidadCredito: modalidadCredito,
    fechaVisita: invertirFormatoFecha(visitaInput.value),
    horaVisita: horaVisita,
  };

  useEffect(() => {
    onGuardarDatos(dataProspeccion);
    //console.log(dataProspeccion);
  }, [modalidadCredito, visitaInput.value, horaVisita]);

  const resetStateFunction = () => {
    setModalidadCredito('');
    setHoraVisita('');
    visitaInput.handleChange('');
  };

  useEffect(() => {
    resetStateFunction();
  }, [resetState]);

  return (
    <View>
      <Text
        h4
        h4Style={{
          backgroundColor: color.brand,
          textAlign: 'center',
          padding: 12,
          color: color.white,
          fontSize: 18,
          marginBottom: 24,
        }}
      >
        Datos de la Prospección
      </Text>
      <View
        style={{
          flexDirection: 'row',
          gap: 16,
          paddingLeft: 16,
          paddingRight: 16,
          marginBottom: 16,
        }}
      >
        <View style={{ flexGrow: 1 }}>
          <MyDropdown
            title="Modalidad de Crédito *"
            placeholder="Elige por favor..."
            endpoint="/categoria/modalidad_credito"
            icon="cash"
            onValueChange={(selectedValued) =>
              setModalidadCredito(selectedValued)
            }
            isError={isModalidadCreditoError}
            errorMessage={
              isModalidadCreditoError
                ? 'Vuelva a seleccionar la modalidad de crédito'
                : ''
            }
            isVisibleSearch={false}
          />
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          gap: 16,
          paddingLeft: 16,
          paddingRight: 16,
          marginBottom: 16,
          flexWrap: 'wrap',
        }}
      >
        <View style={{ flexGrow: 1 }}>
          <View style={{ flexGrow: 1 }}>
            <MyTextInput
              label="Fecha de visita (Dia/Mes/Año) *"
              placeholder="DD/MM/YYYY"
              maxLength={10}
              isIcon
              isLeftIcon
              iconLeftLibrary="material"
              iconLeftName="event"
              isError={isFechaVisitaError}
              errorMessage={
                isFechaVisitaError
                  ? 'Ingresa el formato correcto DD/MM/YYYY'
                  : ''
              }
              {...visitaInput}
            />
          </View>
          {/*
          <TouchableOpacity onPress={() => setShowPicker(true)}>
            <MyTextInput
              label="Fecha de Visita"
              placeholder="DD/MM/YYYY"
              isIcon
              isLeftIcon
              iconLeftLibrary="material"
              iconLeftName="event"
              isError={isFechaVisitaError}
              errorMessage={
                isFechaVisitaError ? "Ingresa una fecha válida" : ""
              }
              editable={true}
              value={registro}
            />
          </TouchableOpacity>
          {showPicker && (
            <DateTimePicker
              mode="date"
              display="calendar"
              value={date}
              onChange={onChangeDate}
            />
          )}
					*/}
        </View>
        <View style={{ flexGrow: 1 }}>
          <MyDropdown
            title="Hora de Visita *"
            placeholder="Selecciona"
            endpoint="/categoria/horarios_visita/normal"
            icon="time"
            onValueChange={(selectedValued) => setHoraVisita(selectedValued)}
            isError={isHoraVisitaError}
            errorMessage={
              isHoraVisitaError ? 'Vuelva a eleccionar la hora de visita' : ''
            }
            isVisibleSearch={false}
          />
        </View>
      </View>
    </View>
  );
};

export default DatosProspeccion;
