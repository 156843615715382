import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * Almacena un elemento en AsyncStorage.
 *
 * @param {string} key - La clave bajo la cual se almacenará el elemento.
 * @param {any} value - El valor que se almacenará (debe ser serializable a JSON).
 * @returns {Promise<void>} - Una promesa que se resuelve cuando la operación de almacenamiento se completa.
 * @throws {Error} - Lanza un error si hay un problema al intentar almacenar el elemento.
 */
export const setItem = async (key, value) => {
	try {
		await AsyncStorage.setItem(key, JSON.stringify(value));
	} catch (error) {
		console.error(`Error al guardar ${key}:`, error);
	}
};

/**
 * Obtiene un elemento almacenado en AsyncStorage.
 *
 * @param {string} key - La clave del elemento que se va a recuperar.
 * @returns {Promise<any>} - Una promesa que se resuelve con el valor recuperado (si existe).
 * @throws {Error} - Lanza un error si hay un problema al intentar recuperar el elemento.
 */
export const getItem = async key => {
	try {
		const savedItem = await AsyncStorage.getItem(key);
		return JSON.parse(savedItem);
	} catch (error) {
		console.error(`Error al obtener ${key}:`, error);
	}
};

/**
 * Elimina un elemento almacenado en AsyncStorage.
 *
 * @param {string} key - La clave del elemento que se va a eliminar.
 * @returns {Promise<void>} - Una promesa que se resuelve cuando la operación de eliminación se completa.
 * @throws {Error} - Lanza un error si hay un problema al intentar eliminar el elemento.
 */
export const removeItem = async key => {
	try {
		await AsyncStorage.removeItem(key);
	} catch (error) {
		console.error(`Error al eliminar ${key}:`, error);
	}
};
