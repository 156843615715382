import React, { useState, useContext } from "react";
import { View, StyleSheet, Text, Image } from "react-native";
import Logo from "@assets/logo.png";
import md5 from "crypto-js/md5";
import { StatusBar } from "expo-status-bar";

import MyTextInput from "@components/MyTextInput";
import useInputValue from "@hooks/useInputValue";
import MyButton from "@components/MyButton";
import { isValidEmail, isValidPassword } from "@utils/validation";
import { AuthContext } from "@context/AuthContext";
import { SafeAreaView } from "react-native-safe-area-context";

/*
El jwt enviado por el backend:
{"access_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwibm9tYnJlIjoiR2VvcmdlIiwiY29ycmVvIjoiZ2VvcmdlYmVsbG90YUBnbWFpbC5jb20iLCJmb3RvIjoibnVsbCIsImlkX3JvbCI6MSwibm9tYnJlX3JvbCI6ImFkbWluaXN0cmFkaXIiLCJpZF9lbXAiOjEsIm5vbWJyZV9lbXByZXNhIjoiR2VhdGVsIiwiaWRfc2VkZSI6MSwibm9tYnJlX3NlZGUiOiJTSk0gRmluYW5jaWVyYSIsImV4cCI6MTcwMjU4MzYwOH0.WZlGLNaANgbyqk5eRMBTdDPMt_OkodlE0iMJUw-xC18", "message": "Autenticación exitosa", "success": true, "token_type": "bearer"}
decodificar el jwt y capturar nombre, id_rol
*/

const LoginScreen = ({ navigation }) => {
  const { login } = useContext(AuthContext);
  const usernameInput = useInputValue("");
  const passwordInput = useInputValue("");

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Estado que permite cambiar el icono de visible a invisible
  const [isVisibilityPassword, setIsVisibiltyPassword] = useState(true);
  const handleIconPress = () => setIsVisibiltyPassword(!isVisibilityPassword);

  const handleLogin = async () => {
    try {
      // Validar el correo electrónico y la contraseña
      //const isEmailValid = isValidEmail(usernameInput.value);
      const isEmailValid = usernameInput.value.length <= 80;
      const isPasswordValid = isValidPassword(passwordInput.value);

      if (!isEmailValid) {
        setEmailError(true);
        return;
      }
      setEmailError(false);

      if (!isPasswordValid) {
        setPasswordError(true);
        return;
      }
      setPasswordError(false);

      setIsLoading(true);

      // Encriptar la contraseña utilizando MD5
      //const encryptedPassword = await md5(passwordInput.value).toString();
      login(usernameInput.value, passwordInput.value);
    } catch (error) {
      console.error(
        "Error al validar los campos o realizar la solicitud:",
        error
      );
      // Maneja el error de alguna manera apropiada para tu aplicación
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = () => {
    navigation.navigate("ForgetPassword");
  };

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar style="auto" />
      <Image source={Logo} style={styles.imageLogo} />
      <Text style={styles.textTitle}>Gestión de Oportunidades Financiera</Text>

      <View style={{ width: "100%", gap: 16 }}>
        <MyTextInput
          label="Ingresa tu DNI"
          placeholder="45621454"
          isIcon
          isLeftIcon
          iconLeftLibrary="material"
          iconLeftName="fingerprint"
          isError={emailError}
          errorMessage={emailError ? "Por favor, ingresa un DNI válido." : ""}
          {...usernameInput}
        />
        <MyTextInput
          label="Ingresa tu contraseña"
          placeholder="************"
          isIcon
          isLeftIcon
          iconLeftLibrary="material"
          iconLeftName="lock"
          isError={passwordError}
          errorMessage={
            passwordError
              ? "La contraseña debe tener al menos 6 caracteres."
              : ""
          }
          secureTextEntry={isVisibilityPassword}
          isRightIcon
          iconRightLibrary="font-awesome"
          iconRightName={isVisibilityPassword ? "eye-slash" : "eye"}
          onRightIconPress={handleIconPress}
          {...passwordInput}
        />
        <MyButton
          title="Iniciar Sesión"
          type="solid"
          isIcon
          iconLibrary="material"
          iconName="login"
          pressButton={handleLogin}
          isLoading={isLoading}
        />
        <MyButton
          title="¿Olvidaste tu contraseña?"
          type="clear"
          isIcon={false}
          pressButton={handleForgotPassword}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  imageLogo: {
    width: "50%",
    height: "25%",
    resizeMode: "contain",
  },
  textTitle: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 48,
  },
  input: {
    width: "100%",
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 10,
  },
  button: {
    backgroundColor: "#5956FF",
    height: 48,
    width: "100%",
    padding: 8,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#fafafa",
  },
  inputError: {
    borderColor: "red", // Cambiar el borde a rojo en caso de error
  },
  errorText: {
    color: "red",
  },
  textInput: {
    fontSize: 16,
    textAlign: "left",
    width: "100%",
    marginBottom: 8,
  },
  forgotPasswordButton: {
    marginTop: 16,
  },
  textForgetPasswordText: {
    fontSize: 14,
    color: "#5956FF",
    textDecorationLine: "underline",
  },
});

export default LoginScreen;
