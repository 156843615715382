import React, { useState, useContext, useEffect } from 'react';
import DatosPersonales from '@components/DatosPersonales';
import DatosProductos from '@components/DatosProductos';
import { ScrollView, View } from 'react-native';
import DatosProspeccion from '@components/DatosProspeccion';
import { Button, Overlay, Image, Text } from 'react-native-elements';
import MyButton from '@components/MyButton';
import color from '@styles/colors';
import {
  isValidPeruvianDNI,
  isValidAddress,
  isValidPersonName,
  isValidPhoneNumber,
} from '@utils/validation';
import { AuthContext } from '@context/AuthContext';
import { Alert } from 'react-native-web';
import { postAxiosa } from '@utils/api';
import { invertirFormatoFecha } from '@utils/convertirFormatoFechaHora';
import Swal from 'sweetalert2';

const FormScreen = ({ navigation }) => {
  const { userToken } = useContext(AuthContext);

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');

  const fechaActual = `${year}-${month}-${day}`;
  //const userToken = '12345';

  const [dniError, setDNIError] = useState(false);
  const [nombreError, setNombreError] = useState(false);
  const [aPaternoError, setAPaternoError] = useState(false);
  const [aMaternoError, setAMaternoError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [departamentoError, setDepartamentoError] = useState(false);
  const [provinciaError, setProvinciaError] = useState(false);
  const [distritoError, setDistritoError] = useState(false);
  const [reset, setReset] = useState(true);
  const [dataProductoErrors, setDataProductoErrors] = useState({
    categoria: false,
    tipificacion: false,
    producto: false,
    perfil: false,
    maf: false,
  });

  const [dataProspeccionErrors, setDataProspeccionErrors] = useState({
    modalidadCredito: false,
    fechaVisita: false,
    horaVisita: false,
  });

  const [formData, setFormData] = useState({
    dataPersonal: {},
    dataProducto: {},
    dataProspeccion: {},
  });

  const handleGuardarDatosPersonales = (data) => {
    setFormData((prevState) => ({
      ...prevState,
      dataPersonal: data,
    }));
  };

  const handleGuardarDatosProducto = (data) => {
    // Si tipificación es No Venta. Asignar valores por defecto en perfil y MAF
    if (data.tipificacion === 2) {
      data.maf = '0';
      data.perfilCliente = 9;
      setFormData((prevState) => ({
        ...prevState,
        dataProspeccion: {
          fechaVisita: fechaActual,
          horaVisita: 1,
          modalidadCredito: 1,
        },
      }));
    }
    setFormData((prevState) => ({
      ...prevState,
      dataProducto: data,
    }));
  };
  const handleGuardarDatosProspeccion = (data) => {
    setFormData((prevState) => ({
      ...prevState,
      dataProspeccion: data,
    }));
  };

  const [visible, setVisible] = useState(false);
  const [resetKey, setResetKey] = useState(true);
  const toggleOverlay = () => {
    setVisible(!visible);
  };

  const handleSubmit = () => {
    const {
      aMaterno,
      aPaterno,
      celular,
      departamento,
      direccion,
      distrito,
      dni,
      nombre,
      provincia,
    } = formData.dataPersonal;
    const {
      tipificacion,
      conceptoTipificacion,
      categoriaProducto,
      perfilCliente,
      maf,
    } = formData.dataProducto;

    //console.log("PROD", formData.dataProducto);

    const { fechaVisita, modalidadCredito, horaVisita } =
      formData.dataProspeccion;

    try {
      //Data Personal
      const dniValue = dni.trim();
      const nombreValue = nombre.trim();
      const aPaternoValue = aPaterno.trim();
      const aMaternoValue = aMaterno.trim();
      const direccionValue = direccion;
      const celularValue = celular?.trim();
      const departamentoValue = departamento; //.trim();
      const provinciaValue = provincia; //.trim();
      const distritoValue = distrito; //.trim();
      const isValidfechaVisitaValue =
        fechaVisita.includes('-') && fechaVisita.length === 10;

      const isEmptyDepartamento = departamentoValue === '';
      const isEmptyProvincia = provinciaValue === '';
      const isEmptyDistrito = distritoValue === '';

      const categoriaValue = tipificacion; //.trim();
      const tipificacionValue = conceptoTipificacion; //.trim();
      const productoValue = categoriaProducto; //.trim();
      const perfilValue = perfilCliente; //.trim();

      const mafValue = maf?.trim();

      const isEmptyCategoria = categoriaValue === '';
      const isEmptyTipificacion = tipificacionValue === '';
      const isEmptyProducto = productoValue === '';
      const isEmptyPerfil = perfilValue === '';
      const isEmptyMAF = mafValue === '';

      const modalidadCreditoValue = modalidadCredito; //.trim();
      const horaVisitaValue = horaVisita; //.trim();

      const isEmptyModalidadCredito = modalidadCreditoValue == '';
      const isEmptyHoraVisita = horaVisitaValue === '';
      const errors = {
        // Personal
        dni: !isValidPeruvianDNI(dniValue),
        nombre: !isValidPersonName(nombreValue),
        aPaterno: !isValidPersonName(aPaternoValue),
        aMaterno: !isValidPersonName(aMaternoValue),
        celular: !isValidPhoneNumber(celularValue),
        /*
        departamento: isEmptyDepartamento,
        provincia: isEmptyProvincia,
        distrito: isEmptyDistrito,
        
        */ // Productos
        departamento: false,
        provincia: false,
        distrito: false,

        categoria: isEmptyCategoria,
        tipificacion: isEmptyTipificacion,
        producto: isEmptyProducto,
        //perfil: isEmptyPerfil,
        perfil: false,
        //maf: isEmptyMAF,
        maf: false,

        // Prospección
        fechaVisita: !isValidfechaVisitaValue,
        modalidadCredito: isEmptyModalidadCredito,
        horaVisita: isEmptyHoraVisita,
      };

      setDNIError(errors.dni);
      setNombreError(errors.nombre);
      setAPaternoError(errors.aPaterno);
      setAMaternoError(errors.aMaterno);
      setAddressError(errors.direccion);
      setPhoneError(errors.celular);
      setDepartamentoError(errors.departamento);
      setProvinciaError(errors.provincia);
      setDistritoError(errors.distrito);
      setDataProductoErrors({
        categoria: errors.categoria,
        tipificacion: errors.tipificacion,
        producto: errors.producto,
        perfil: errors.perfil,
        maf: errors.maf,
      });
      setDataProspeccionErrors({
        modalidadCredito: errors.modalidadCredito,
        fechaVisita: errors.fechaVisita,
        horaVisita: errors.horaVisita,
      });
      if (!Object.values(errors).some((error) => error)) {
        // Realizar la acción, enviar el formulario, etc.
        //console.log("Datos válidos, enviar formulario");
        postFormulario(formData, userToken);
      } else {
        //console.log('Datos invalidos, no se enviará');
        console.log('errors', errors);
        const valoresTrue = Object.keys(errors).filter((key) => errors[key]);

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Ingresa correctamente los siguientes campos ${valoresTrue}`,
        });
      }
    } catch (error) {
      console.error('Error al validar los datos:', error);

      if (error instanceof TypeError) {
        console.error('Error de tipo:', error.message);
      } else if (error instanceof SyntaxError) {
        console.error('Error de sintaxis:', error.message);
      } else {
        console.error('Error desconocido:', error.message);
      }
    }
  };
  const [dataUserDNI, setDataUserDNI] = useState({});
  const postFormulario = async (formData, accessToken) => {
    const profileUrl = `https://apps.geatel-telecom.com:9005/registrar/cliente`;
    const profileRequestOptions = accessToken;

    setReset(!reset);
    try {
      const profileResponse = await postAxiosa(
        profileUrl,
        profileRequestOptions,
        formData
      );
      if (profileResponse.success) {
        Alert.alert(
          'Envio exitoso',
          'Se ha enviado correctamente la información'
        );
      }
      setDataUserDNI(profileResponse.detail);
      //console.log(profileResponse.detail);
      toggleOverlay();
      if (profileResponse.detail.success) {
        setTimeout(() => {
          setResetKey(!resetKey);
          navigation.navigate('Formulario');
          setVisible(false);
        }, 2000); // Retraso de 5000 milisegundos (5 segundos)
      } // Verifica si la respuesta contiene datos válidos
    } catch (error) {
      Alert.alert(error);
      console.log('ERROR', error);
      throw error;
    }
  };

  return (
    <ScrollView>
      <DatosPersonales
        onGuardarDatos={handleGuardarDatosPersonales}
        isDNIError={dniError}
        isNombreError={nombreError}
        isAPaternoError={aPaternoError}
        isAMaternoError={aMaternoError}
        isAddressError={addressError}
        isPhoneError={phoneError}
        isDepartamentoError={departamentoError}
        isProvinciaError={provinciaError}
        isDistritoError={distritoError}
        resetState={resetKey}
      />

      <DatosProductos
        onGuardarDatos={handleGuardarDatosProducto}
        isCategoriaError={dataProductoErrors.categoria}
        isTipificacionError={dataProductoErrors.tipificacion}
        isProductoError={dataProductoErrors.producto}
        isPerfilError={dataProductoErrors.perfil}
        isMAFError={dataProductoErrors.maf}
        resetState={resetKey}
      />
      {formData.dataProducto.tipificacion != 2 ? (
        <DatosProspeccion
          onGuardarDatos={handleGuardarDatosProspeccion}
          isModalidadCreditoError={dataProspeccionErrors.modalidadCredito}
          isFechaVisitaError={dataProspeccionErrors.fechaVisita}
          isHoraVisitaError={dataProspeccionErrors.horaVisita}
          resetState={resetKey}
        />
      ) : (
        <></>
      )}

      <View
        style={{
          flex: 1,
          width: '100%',
          paddingHorizontal: 16,
          marginBottom: 16,
        }}
      >
        <MyButton
          title="Registrar"
          type="solid"
          isIcon
          iconLibrary="material"
          iconName="save"
          pressButton={handleSubmit}
        />
      </View>
      <Overlay
        isVisible={visible}
        onBackdropPress={toggleOverlay}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            source={{
              uri: dataUserDNI.success
                ? 'https://static-00.iconduck.com/assets.00/success-icon-512x512-qdg1isa0.png'
                : 'https://cdn-icons-png.flaticon.com/512/564/564619.png',
            }}
            style={{
              height: 100,
              width: 100,
              marginBottom: 16,
            }}
          />
        </View>
        <Text
          h3
          h3Style={{
            marginBottom: 8,
            color: dataUserDNI.success ? color.success : color.alert,
            textAlign: 'center',
          }}
        >
          {dataUserDNI.success
            ? 'Cliente Registrado'
            : 'No se puede registrar al Cliente'}
        </Text>
        <Text style={{ textAlign: 'center', fontSize: 18 }}>
          {dataUserDNI.msg}
        </Text>
      </Overlay>
    </ScrollView>
  );
};

export default FormScreen;
