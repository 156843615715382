import { useState } from 'react';

/**
 * Custom hook para gestionar el estado de un campo de entrada.
 *
 * @param {string} [initialValue=''] - El valor inicial del campo de entrada.
 * @returns {object} - Un objeto que contiene el valor actual del campo de entrada,
 * la función para cambiar el valor (`handleChange`), y la función para borrar el valor (`clear`).
 */
const useInputValue = (initialValue = '') => {
	// Estado local para almacenar el valor del campo de entrada
	const [value, setValue] = useState(initialValue);

	/**
	 * Función para cambiar el valor del campo de entrada.
	 * @param {string} text - El nuevo valor del campo de entrada.
	 */
	const handleChange = text => {
		setValue(text);
	};

	/**
	 * Función para borrar el valor del campo de entrada.
	 */
	const clear = () => {
		setValue('');
	};

	// Devuelve un objeto con el valor actual, la función para cambiar el valor y la función para borrar el valor
	return {
		value,
		handleChange,
		clear,
	};
};

export default useInputValue;
