import React, { useState } from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import MyButton from '@components/MyButton';
import MyTextInput from '@components/MyTextInput';
import { isValidEmail } from '@utils/validation';
import useInputValue from '@hooks/useInputValue';
import { Text, Image } from 'react-native-elements';
import OLVIDO_CONTRASEÑA from '@/assets/image/forgot_password.gif';
import color from '@styles/colors';
import { Dimensions } from 'react-native';
const { width: screenWidth, height: screenHeight } = Dimensions.get('window');

const ForgotPasswordScreen = ({ navigation }) => {
	const [emailError, setEmailError] = useState(false);

	const usernameInput = useInputValue('');

	const handleSendEmail = () => {
		try {
			// Validar el correo electrónico y la contraseña
			const isEmailValid = isValidEmail(usernameInput.value);

			if (!isEmailValid) {
				setEmailError(true);
				return;
			}
			setEmailError(false);
		} catch (error) {
			console.error(
				'Error al validar los campos o realizar la solicitud:',
				error
			);
		}
	};

	const handleBackLogin = () => navigation.navigate('Login');
	return (
		<SafeAreaView edges={['bottom', 'left', 'right']} style={styles.container}>
			<ScrollView>
				<View style={styles.imageContainer}>
					<Image source={OLVIDO_CONTRASEÑA} style={styles.image} />
				</View>

				<View style={styles.contentContainer}>
					<Text style={styles.text}>
						Para recuperar la contraseña, ingresa tu correo electrónico asociado
						con tu cuenta.
					</Text>

					<MyTextInput
						label='Ingresa tu correo'
						placeholder='usuario@neointelperu.com'
						isIcon
						isLeftIcon
						iconLeftLibrary='material'
						iconLeftName='email'
						isError={emailError}
						errorMessage={
							emailError ? 'Por favor, ingresa un correo válido.' : ''
						}
						{...usernameInput}
					/>

					<MyButton
						title='Enviar solicitud'
						type='solid'
						isIcon
						iconLibrary='material'
						iconName='send'
						pressButton={handleSendEmail}
					/>

					<MyButton
						title='Volver a Iniciar Sesión'
						type='clear'
						isIcon
						iconLibrary='material'
						iconName='arrow-back'
						pressButton={handleBackLogin}
					/>
				</View>
			</ScrollView>
		</SafeAreaView>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'white',
	},
	imageContainer: {
		flex: 1,
		justifyContent: 'center',
	},
	image: {
		resizeMode: 'contain',
		width: '100%',
		height: screenHeight * 0.5,
	},
	contentContainer: {
		flex: 1,
		paddingHorizontal: 32,
		gap: 16,
	},
	text: {
		fontSize: 18,
		textAlign: 'justify',
	},
});

export default ForgotPasswordScreen;
