import React, { useState, useEffect, useContext } from 'react';
import {
  View,
  TouchableOpacity,
  FlatList,
  Alert,
  ScrollView,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Avatar, ListItem, Text } from 'react-native-elements';
import MyButton from '@components/MyButton';
import colors from '@styles/colors';
import { AuthContext } from '@context/AuthContext';
import { ActivityIndicator } from 'react-native';
import Error_401 from '@components/Error_401';
import MyTextInput from '@components/MyTextInput';
import {
  convertirFechaHora,
  convertirFecha,
  invertirFormatoFecha,
  obtenerDiasFaltantes,
} from '@utils/convertirFormatoFechaHora';

const AdministrarFormularioScreen = ({ navigation }) => {
  const [reset, setReset] = useState(true);
  const { userToken, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [listUsers, setListUsers] = useState([]);
  const currentDate = new Date().toLocaleString('es-PE', {
    timeZone: 'America/Lima',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const [page, setPage] = useState(1);
  const itemsPerPage = 100;
  let hasMoreData = true;
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [registrosCargados, setRegistrosCargados] = useState(0);
  const [estadoOverlay, setEstadoOverlay] = useState(false);
  const [reiniciarLista, setReiniciarLista] = useState(false);

  const [desactivarCarga, setDesactivarCarga] = useState(false);
  const fetchData = async () => {
    if (estadoOverlay) return;
    try {
      if (loading || !hasMoreData) {
        // Evitar realizar más solicitudes si ya se está cargando o no hay más datos
        return;
      }

      setLoading(true);
      // Calcular el offset
      const offset = (page - 1) * itemsPerPage;
      //const url = `https://apps.geatel-telecom.com:9005/asesor/listado_prospeccion/${offset}/100`;
      const url = `https://apps.geatel-telecom.com:9005/asesor/listado_prospeccion/${inicioFecha}/${finFecha}T23:59:59/${offset}/100`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      });
      //console.log(response);
      if (!response.ok) {
        if (response.status === 401) {
          logout();
          setEstadoOverlay(true);
        } else {
          throw new Error(`Error de red - ${response.status}`);
        }
        return;
      }

      const responseBody = await response.json();
      setTotalRegistros(responseBody.detail.total_registros);
      setRegistrosCargados(
        (prev) => prev + responseBody.detail.total_registros
      );

      if (responseBody.detail.total_registros === 0) {
        setDesactivarCarga(true);
      } else {
        setDesactivarCarga(false);
      }

      if (!responseBody.detail.data) {
        // Si no hay más datos, actualiza el estado para indicar que no hay más datos disponibles
        hasMoreData = false;
        return;
      }

      setListUsers([...listUsers, ...responseBody.detail.data]);
      setPage(page + 1);
    } catch (error) {
      let errorMessage = 'Hubo un problema al obtener los datos.';
      if (error.response) {
        errorMessage = `Error: ${error.response.status} - ${error.response.data.message}`;
      } else if (error.request) {
        errorMessage = 'No se pudo obtener respuesta del servidor.';
      } else {
        console.error('no', error);
        errorMessage = 'Error en la solicitud.';
      }
      console.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => console.log('repe'); //fetchData();
  const renderItem = ({ item }) => (
    <ListItem
      bottomDivider
      containerStyle={{
        opacity:
          obtenerDiasFaltantes(
            invertirFormatoFecha(currentDate),
            item.fecha_visita
          ) -
            1 >
          3
            ? 1
            : 0.5,
      }}
      //onPress={() => console.log('abrio el item', item.dni)}
      onPress={() =>
        navigation.navigate('DetalleCliente', { userId: item.id_grupo })
      }
    >
      <ListItem.Content>
        <ListItem.Title>DNI: {item.nro_documento}</ListItem.Title>
        <ListItem.Subtitle>
          Fecha de Registro: {convertirFechaHora(item.creacion)}
        </ListItem.Subtitle>

        <ListItem.Subtitle
          style={{
            color:
              obtenerDiasFaltantes(
                invertirFormatoFecha(currentDate),
                item.fecha_visita
              ) -
                1 >
              3
                ? colors.success
                : colors.alert,
            fontWeight: 'bold',
          }}
        >
          {obtenerDiasFaltantes(
            invertirFormatoFecha(currentDate),
            item.fecha_visita
          ) < 0
            ? `Fecha de visita vencida`
            : `Visita en ${obtenerDiasFaltantes(
                invertirFormatoFecha(currentDate),
                item.fecha_visita
              )} días`}
        </ListItem.Subtitle>
      </ListItem.Content>
      <ListItem.Content>
        <ListItem.Title>Nombre: {item.nombre}</ListItem.Title>
        <ListItem.Subtitle>
          Fecha y Hora de Visita: {convertirFecha(item.fecha_visita)}
        </ListItem.Subtitle>
        <ListItem.Subtitle>
          {`${item.hora_inicio.slice(0, -3)} a ${item.hora_fin.slice(0, -3)}`}
        </ListItem.Subtitle>
      </ListItem.Content>
    </ListItem>
  );

  const handleBuscarProspeccionPorDNI = async (searchTerm) => {
    if (!searchTerm) return;
    const offset = 0;
    const limit = 100;
    const dni = searchTerm;
    const url = `https://apps.geatel-telecom.com:9005/busqueda/asesor/listado_prospeccion/${offset}/${limit}/dni/${dni}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json', // Esto puede ser necesario según las necesidades de tu API
        },
      });

      if (!response.ok) {
        throw new Error(`Error de red - ${response.status}`);
      }

      const responseBody = await response.json();
      setFilteredUsers(responseBody.detail.data);
    } catch (error) {
      let errorMessage = 'Hubo un problema al obtener los datos.';
      if (error.response) {
        console.log('si', error);
        errorMessage = `Error: ${error.response.status} - ${error.response.data.message}`;
      } else if (error.request) {
        errorMessage = 'No se pudo obtener respuesta del servidor.';
      } else {
        errorMessage = 'Error en la solicitud.';
      }
      console.error(errorMessage);
    }
  };
  const [isBorrarVisible, setIsBorrarVisible] = useState(false);

  const [termValue, setTermValue] = useState('');

  const [cargarMas, setCargarMas] = useState(false);
  const activarCargarMas = () => fetchData();

  useEffect(() => {
    fetchData();
  }, [reset]);

  function obtenerPrimerDiaDelMes() {
    var fecha = new Date();
    var primerDia = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
    var formato =
      primerDia.getFullYear() +
      '-' +
      ('0' + (primerDia.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + primerDia.getDate()).slice(-2);
    return formato;
  }

  function obtenerFechaDeHoy() {
    var fecha = new Date();
    var formato =
      fecha.getFullYear() +
      '-' +
      ('0' + (fecha.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + fecha.getDate()).slice(-2);
    return formato;
  }

  const [inicioFecha, setInicioFecha] = useState(obtenerPrimerDiaDelMes());
  const [finFecha, setFinFecha] = useState(obtenerFechaDeHoy());

  const handleDateInicioFechaChange = (event) => {
    setInicioFecha(event.target.value);
  };

  const handleDateFinFechaChange = (event) => {
    setFinFecha(event.target.value);
  };

  //const [selected, setSelected] = useState('1'); // Inicialmente seleccionado el botón 2
  /*
  const handleRadioChange = (event) => {
    setSelected(event.target.id);
  };

  useEffect(() => {
    console.log('SELECCCION', selected);
  }, [selected]);
  */
  return (
    <ScrollView>
      <SafeAreaView
        edges={['bottom', 'left', 'right']}
        style={{
          flex: 1,
          backgroundColor: colors.white,
          paddingBottom: 0,
        }}
      >
        <View
          style={{
            gap: 8,
            marginHorizontal: 16,
            marginTop: 16,
            backgroundColor: colors.white,
          }}
        >
          <View>
            <MyTextInput
              label=""
              keyboardType="numeric"
              maxLength={8}
              placeholder="Buscar por DNI del cliente"
              isIcon
              isLeftIcon={false}
              isRightIcon
              iconRightLibrary="material"
              iconRightName="search"
              onRightIconPress={() => handleBuscarProspeccionPorDNI(searchTerm)}
              onChangeText={(text) => {
                setSearchTerm(text);
                setIsBorrarVisible(true);
                if (text.length === 0) setIsBorrarVisible(false);
                setTermValue(text);
              }}
              value={termValue}
            />
            <TouchableOpacity
              onPress={() => {
                setRegistrosCargados(0);
                setTotalRegistros(0);
                setPage(1);
                setTermValue('');
                setIsBorrarVisible(false);
                setFilteredUsers([]);
                setListUsers([]);
                setSearchTerm('');
                setReset(!reset);
              }}
              style={{
                display: isBorrarVisible ? 'flex' : 'none',
                position: 'absolute',
                right: 50,
                paddingHorizontal: 4,
                bottom: 0,
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text style={{ color: colors.placeholder }}>Borrar</Text>
            </TouchableOpacity>
          </View>
          <div>
            <Text
              style={{ fontWeight: 'bold', color: '#5958fe', fontSize: '16px' }}
            >
              Filtrar por fecha
            </Text>
            <div
              style={{
                display: 'flex',
                gap: 8,
                justifyContent: 'space-between',
                margin: '8px auto',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column-reverse',
                  gap: 8,
                  maxWidth: '175px',
                }}
              >
                <input
                  type="date"
                  style={{
                    borderRadius: '8px',
                    padding: '8px 16px',
                    border: '1px solid #9ca3af',
                  }}
                  value={inicioFecha}
                  onChange={handleDateInicioFechaChange}
                />
                <Text htmlFor="todoRadio">Inicio de la prospección</Text>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column-reverse',
                  gap: 8,
                  maxWidth: '175px',
                }}
              >
                <input
                  type="date"
                  style={{
                    borderRadius: '8px',
                    padding: '8px 16px',
                    border: '1px solid #9ca3af',
                  }}
                  value={finFecha}
                  onChange={handleDateFinFechaChange}
                />
                <Text htmlFor="activoRadio">Fin de la prospección</Text>
              </div>
            </div>
          </div>
          {/*
          <div>
            <Text
              style={{ fontWeight: 'bold', color: '#5958fe', fontSize: '16px' }}
            >
              Filtrar por estado de visita
            </Text>
            <div
              style={{
                display: 'flex',
                gap: 8,
                justifyContent: 'space-between',
                margin: '8px 0',
              }}
            >
              <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                <input
                  type="radio"
                  id="2"
                  name="grupoEstados"
                  checked={selected === '2'}
                  onChange={handleRadioChange}
                />
                <Text htmlFor="2">Todos</Text>
              </div>
              <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                <input
                  type="radio"
                  id="1"
                  name="grupoEstados"
                  checked={selected === '1'}
                  onChange={handleRadioChange}
                />
                <Text htmlFor="1">Activos</Text>
              </div>
              <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                <input
                  type="radio"
                  id="0"
                  name="grupoEstados"
                  checked={selected === '0'}
                  onChange={handleRadioChange}
                />
                <Text htmlFor="0">Vencidos</Text>
              </div>
            </div>
          </div>
            */}
          <MyButton
            title="Actualizar lista"
            type="outline"
            isIcon
            iconLibrary="material"
            iconName="update"
            pressButton={() => {
              setRegistrosCargados(0);
              setTotalRegistros(0);
              setPage(1);
              setTermValue('');
              setIsBorrarVisible(false);
              setFilteredUsers([]);
              setListUsers([]);
              setSearchTerm('');
              setReset(!reset);
            }}
          />
        </View>
        <FlatList
          data={
            filteredUsers && filteredUsers.length > 0
              ? filteredUsers
              : listUsers
          }
          renderItem={renderItem}
          keyExtractor={(item, index) => index}
          //onEndReached={loadMore}
          //onEndReached={activarCargarMas}
          onEndReachedThreshold={0.1}
          ListFooterComponent={
            loading ? (
              <ActivityIndicator
                size="large"
                style={{ marginTop: 20, marginBottom: 20 }}
                color={colors.brand}
              />
            ) : null
          }
        />
        {hasMoreData && !desactivarCarga ? (
          <button
            style={{
              background: '#5958fe',
              color: 'white',
              borderRadius: 10,
              fontSize: 14,
              height: '36px',
              margin: '12px 32px',
              border: 'none',
            }}
            onClick={activarCargarMas}
          >
            Cargar más...
          </button>
        ) : (
          <></>
        )}
        <Error_401
          setMostrarVistaCerrarSesion={setEstadoOverlay}
          mostrarVistaCerrarSesion={estadoOverlay}
        />
      </SafeAreaView>
    </ScrollView>
  );
};

export default AdministrarFormularioScreen;
