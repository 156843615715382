/**
 * Realiza una solicitud para obtener detalles de un usuario por su ID desde una API mediante el método GET.
 *
 * @param {number} userId - Identificador único del usuario que se desea buscar.
 * @param {string} accessToken - Token de acceso del usuario para autenticar la solicitud (opcional).
 * @returns {Promise<object>} - Una promesa que resuelve en un objeto con la información del usuario.
 * @throws {Error} - Se lanza en caso de problemas durante la solicitud a la API.
 *
 */
import { getAxios } from '@/utils/api';
import { BASE_URL } from '@/const';

export const getUserById = async (userId, accessToken) => {
	const userUrl = `https://api.escuelajs.co/api/v1/products/${userId}`;

	const profileRequestOptions = accessToken
		? {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
		  }
		: {};

	try {
		const response = await getAxios(userUrl, profileRequestOptions);
		return response.data;
	} catch (error) {
		throw error;
	}
};
