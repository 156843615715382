// requestUtils.js
import axios from 'axios';
import { Alert } from 'react-native-web';

/**
 * Realiza una solicitud GET con Axios y maneja la respuesta.
 *
 * @param {string} url - URL de la solicitud GET.
 * @param {object} headers - Cabeceras de la solicitud (opcional).
 * @returns {Promise<object>} - La respuesta de la solicitud.
 * @throws {Error} - Se lanza en caso de errores durante la solicitud.
 */
export const getAxios = async (url, headers = {}) => {
  try {
    const response = await axios.get(url, headers);
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Realiza una solicitud POST con Axios y maneja la respuesta.
 *
 * @param {string} url - URL de la solicitud POST.
 * @param {object} data - Datos que se enviarán en la solicitud POST.
 * @param {object} headers - Cabeceras de la solicitud (opcional).
 * @returns {Promise<object>} - La respuesta de la solicitud.
 * @throws {Error} - Se lanza en caso de errores durante la solicitud.
 */
export const postAxios = async (url, data, headers = {}) => {
  try {
    const response = await axios.post(url, data, { headers });
    return response;
  } catch (error) {
    throw error;
  }
};

export const postAxiosa = async (url, headersa, data) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${headersa}`,
        'Content-Type': 'application/json', // Ajusta el tipo de contenido según sea necesario
      },
      body: JSON.stringify(data),
      // Si necesitas enviar datos en el cuerpo de la solicitud, puedes hacerlo así:
      // body: JSON.stringify(data)
    });
    /*
    if(response.status === 422) {
      window.alert("Alert", "error es -> ",response.json().detail.msg )
    }
    console.log('res', response.status);
    console.log('JSON', JSON.stringify(response));
    console.log('AS', response.json());    

    const responseData = await response.json();
    console.log('res', responseData);
    return responseData;
  } catch (error) {
    throw error;
  }
  */
    if (!response.ok) {
      // Si el estado no es OK (200-299), manejar el error
      if (response.status === 422) {
        const errorData = await response.json();
        throw { status: response.status, detail: errorData };
      } else {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    // Manejar el error aquí
    if (error instanceof SyntaxError) {
      // Error de análisis JSON
      console.error('Error de análisis JSON', error);
    } else {
      // Otro tipo de error
      console.error('Error en la solicitud', error.message);
      if (error.status === 422) {
        window.alert(
          `Error: ${error.status} Se ingreso mal un campo. Por favor, seguir el formato de cada campo correctamente.`
        );
      } else {
        window.alert('Error inesperado');
      }
    }
  }
};
