import React, { useContext } from 'react';
import { Text, SafeAreaView, TextInput } from 'react-native';
import MyTextInput from '@components/MyTextInput';
import { Avatar } from 'react-native-elements';
import { AuthContext } from '@context/AuthContext';
import { ScrollView } from 'react-native';

const ProfileScreen = () => {
	const { userInfo } = useContext(AuthContext);
	return (
		<ScrollView>
			<SafeAreaView style={{ alignItems: 'center', gap: 24, padding: 32 }}>
				<Avatar
					size={128}
					rounded
					source={{ uri: userInfo.foto }}
					key={`userInfo.id`}
				/>

				<MyTextInput
					label='DNI'
					isIcon
					isLeftIcon
					iconLeftLibrary='material'
					iconLeftName='fingerprint'
					value={userInfo.nroDoc}
					editable={false}
				/>
				<MyTextInput
					label='Nombre'
					isIcon
					isLeftIcon
					iconLeftLibrary='material'
					iconLeftName='person'
					value={[userInfo.nombre, userInfo.aPaterno, userInfo.aMaterno]
						.filter(Boolean)
						.join(' ')}
					editable={false}
				/>
				<MyTextInput
					label='Rol'
					isIcon
					isLeftIcon
					iconLeftLibrary='material'
					iconLeftName='work'
					value={userInfo.nomRol}
					editable={false}
				/>
				<MyTextInput
					label='Correo electrónico'
					isIcon
					isLeftIcon
					iconLeftLibrary='material'
					iconLeftName='email'
					value={userInfo.correo}
					editable={false}
				/>
				<MyTextInput
					label='Celular'
					isIcon
					isLeftIcon
					iconLeftLibrary='material'
					iconLeftName='phone'
					value={userInfo.telefono}
					editable={false}
				/>
				<MyTextInput
					label='Dirección'
					isIcon
					isLeftIcon
					iconLeftLibrary='material'
					iconLeftName='place'
					value={userInfo.direccion}
					editable={false}
				/>
				<MyTextInput
					label='Fecha de Nacimiento'
					isIcon
					isLeftIcon
					iconLeftLibrary='material'
					iconLeftName='event'
					value={userInfo.fechaNacimiento}
					editable={false}
				/>
			</SafeAreaView>
		</ScrollView>
	);
};

export default ProfileScreen;
