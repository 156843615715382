import React, { useState, useEffect } from 'react';
import {
	Text,
	SafeAreaView,
	ScrollView,
	TextInput,
	ActivityIndicator,
	View,
} from 'react-native';
import MyTextInput from '@components/MyTextInput';
import { Avatar } from 'react-native-elements';
import { getUserById } from '@utils/getUserByID';
import MyButton from '@components/MyButton';

const DetailUserScreen = ({ route }) => {
	const { userId } = route.params;
	const [userDetails, setUserDetails] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchUserDetails = async () => {
			try {
				// Obtén los detalles del usuario por ID
				const user = await getUserById(userId);

				// Actualiza el estado con los detalles del usuario
				setUserDetails(user);
				setLoading(false); // Marca como no cargando una vez que obtienes los detalles
			} catch (error) {
				console.error('Error al obtener detalles del usuario:', error);
				setLoading(false); // Marca como no cargando en caso de error
			}
		};

		// Llama a la función para cargar los detalles del usuario
		fetchUserDetails();
	}, [userId]);

	if (loading) {
		// Muestra un indicador de carga mientras se obtienen los detalles del usuario
		return (
			<SafeAreaView
				style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
				<ActivityIndicator size='large' />
			</SafeAreaView>
		);
	}

	// Si userDetails es null, muestra un mensaje indicando que no se pudieron obtener los detalles
	if (!userDetails) {
		return (
			<SafeAreaView
				style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
				<Text>Error al obtener detalles del usuario</Text>
			</SafeAreaView>
		);
	}
	return (
		<ScrollView>
			<View style={{ alignItems: 'center', gap: 24, padding: 32 }}>
				<Avatar
					size={128}
					rounded
					source={{ uri: userDetails.images[0] }}
					key={`userInfo.id`}
				/>

				<MyTextInput
					label='DNI'
					isIcon
					isLeftIcon
					iconLeftLibrary='material'
					iconLeftName='fingerprint'
					value={JSON.stringify(userDetails.id)}
					editable={false}
				/>
				<MyTextInput
					label='Nombre'
					isIcon
					isLeftIcon
					iconLeftLibrary='material'
					iconLeftName='person'
					value={userDetails.title}
				/>
				<MyTextInput
					label='Rol'
					isIcon
					isLeftIcon
					iconLeftLibrary='material'
					iconLeftName='work'
					value={userDetails.category.name}
				/>
				<MyTextInput
					label='Correo electrónico'
					isIcon
					isLeftIcon
					iconLeftLibrary='material'
					iconLeftName='email'
					value={userDetails.description}
				/>
				<View style={{ width: '100%', gap: 16 }}>
					<MyButton
						title='Actualizar datos'
						type='solid'
						isIcon
						iconLibrary='material'
						iconName='save'
					/>
					<MyButton
						title='Eliminar usuario'
						type='solid'
						isIcon
						iconLibrary='material'
						iconName='delete'
						isAlert
					/>
				</View>
			</View>
		</ScrollView>
	);
};

export default DetailUserScreen;
