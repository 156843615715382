import React from 'react';
import { Button, Icon } from 'react-native-elements';
import color from '@styles/colors';

const MyButton = ({
	title,
	type,
	isLoading,
	isIcon,
	iconLibrary,
	iconName,
	pressButton,
	isAlert,
}) => {
	const getColor = type => {
		if (type === 'solid') {
			return color.white;
		} else {
			return color.brand;
		}
	};

	return (
		<Button
			title={title}
			type={type}
			loading={isLoading}
			buttonStyle={{
				height: 48,
				borderRadius: 10,
				backgroundColor: isAlert
					? color.alert
					: type === 'solid'
					? color.brand
					: type === 'outline'
					? color.white
					: 'transparent',
				borderColor: type === 'outline' && color.brand,
				gap: isIcon ? 8 : 0,
			}}
			titleStyle={{
				color: getColor(type),
			}}
			icon={<Icon type={iconLibrary} name={iconName} color={getColor(type)} />}
			onPress={pressButton}
		/>
	);
};

export default MyButton;
