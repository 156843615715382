import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { Dropdown } from "react-native-element-dropdown";
import Ionicons from "react-native-vector-icons/Ionicons";
import { Text } from "react-native-elements";
import color from "@styles/colors";
import { getAxios } from "@utils/api";
import { BASE_URL } from "@/const";
import { getColor } from "@utils/getColor";

const MyDropdown = ({
  title,
  placeholder,
  endpoint,
  icon,
  onValueChange,
  isVisibleSearch,
  isDisabled = false,
  isError = false,
  errorMessage = "",
}) => {
  const [value, setValue] = useState(null);
  const [isFocus, setIsFocus] = useState(false);
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const response = await getAxios(`${BASE_URL}${endpoint}`);

      setData(response.data.detail.data);
    } catch (error) {
      let errorMessage = "Hubo un problema al obtener los datos.";
      if (error.response) {
        // El servidor respondió con un código de estado diferente a 2xx
        errorMessage = `Error: ${error.response.status} - ${error.response.data.message}`;
      } else if (error.request) {
        // La petición se hizo pero no se recibió respuesta
        errorMessage = "No se pudo obtener respuesta del servidor.";
      } else {
        // Otro tipo de errores
        errorMessage = "Error en la solicitud.";
      }
      console.error(errorMessage);
    }
  };

  useEffect(() => {
    setData([]);
    getData();
  }, [endpoint]);

  return (
    <>
      <Text
        style={{
          fontSize: 16,
          fontWeight: "bold",
          color: getColor(isError, isFocus),
          marginBottom: 8,
        }}
      >
        {title}
      </Text>
      <Dropdown
        style={{
          height: 50,
          borderColor: getColor(isError, isFocus),
          borderWidth: isFocus ? 2 : 1,
          borderRadius: 8,
          paddingHorizontal: 8,
        }}
        placeholderStyle={{ color: color.placeholder }}
        selectedTextStyle={styles.selectedTextStyle}
        inputSearchStyle={styles.inputSearchStyle}
        iconStyle={styles.iconStyle}
        data={data}
        search={isVisibleSearch}
        maxHeight={300}
        labelField="opcion"
        valueField="valor"
        placeholder={!isFocus ? placeholder : "..."}
        searchPlaceholder="Buscar..."
        value={value}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onChange={(item) => {
          setValue(item.valor);
          setIsFocus(false);
          onValueChange(item.valor);
        }}
        renderLeftIcon={() => (
          <Ionicons
            style={styles.icon}
            size={20}
            name={icon}
            color={getColor(isError, isFocus)}
          />
        )}
        disable={isDisabled}
      />
      {isError ? (
        <Text
          h4
          h4Style={{
            paddingTop: 4,
            color: color.alert,
            fontSize: 14,
            fontWeight: "normal",
          }}
        >
          {errorMessage}
        </Text>
      ) : (
        <></>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  icon: {
    marginRight: 5,
  },
  label: {
    position: "absolute",
    backgroundColor: "white",
    left: 22,
    top: 8,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
});

export default MyDropdown;
