import React, { useState, useEffect, useContext } from 'react';
import { View, Dimensions } from 'react-native';
import { Overlay, Image, Text } from 'react-native-elements';
import ERROR_401 from '@/assets/image/Error_401.gif';
import { AuthContext } from '@context/AuthContext';
import MyButton from '@components/MyButton';
import colors from '@styles/colors';
const { width: screenWidth, height: screenHeight } = Dimensions.get('window');

const Error_401 = ({
	mostrarVistaCerrarSesion,
	setMostrarVistaCerrarSesion,
}) => {
	const { logout } = useContext(AuthContext);
	const redireccionarLogin = () => {
		setMostrarVistaCerrarSesion(!mostrarVistaCerrarSesion);
		logout();
	};

	return (
		<Overlay
			isVisible={mostrarVistaCerrarSesion}
			onBackdropPress={redireccionarLogin}
			style={{
				alignItems: 'center',
				justifyContent: 'center',
				flex: 1,
				padding: 32,
			}}>
			<View
				style={{
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<Image
					source={ERROR_401}
					style={{
						resizeMode: 'contain',
						width: screenWidth,
						height: screenHeight * 0.5,
					}}
				/>
			</View>
			<View style={{ padding: 32, gap: 16 }}>
				<Text
					h3
					h3Style={{
						marginBottom: 8,
						color: colors.brand,
						textAlign: 'center',
					}}>
					ERROR 401
				</Text>
				<Text style={{ textAlign: 'center', fontSize: 18 }}>
					Su sesión ha caducado, vuelva a iniciar sesión.
				</Text>
				<MyButton
					title='Regresar al login'
					type='solid'
					pressButton={redireccionarLogin}
				/>
			</View>
		</Overlay>
	);
};

export default Error_401;
