import "react-native-gesture-handler";
import React, { useState, useEffect } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import NetInfo from "@react-native-community/netinfo";
import { AuthProvider } from "@context/AuthContext";
import Main from "@/Main";
import NoInternetScreen from "@screens/NoInternetScreen";

/**
 * Componente principal de la aplicación.
 * Maneja la conectividad de red y renderiza las pantallas apropiadas según el estado de conexión.
 */
export default function App() {
  // Estado para realizar un seguimiento de la conectividad de red
  const [isConnected, setIsConnected] = useState(true);

  // Efecto para suscribirse a cambios en la conectividad de red
  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener(({ isConnected }) =>
      setIsConnected(isConnected)
    );

    return unsubscribe; // Limpieza al desmontar el componente
  }, []);

  return (
    <AuthProvider>
      {isConnected ? (
        <SafeAreaProvider>
          <Main />
        </SafeAreaProvider>
      ) : (
        <SafeAreaProvider>
          <NoInternetScreen />
        </SafeAreaProvider>
      )}
    </AuthProvider>
  );
}
