import React, { useState, createContext, useEffect } from 'react';
import { getItem, removeItem, setItem } from '@utils/storage';
import { BASE_URL } from '@/const';
import { Alert } from 'react-native';
import { getUserInfo } from '@utils/getUserInfo';
import { postAxios } from '@utils/api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userToken, setUserToken] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  const login = async (email, password) => {
    try {
      setIsLoading(true);
      const url = 'https://apps.geatel-telecom.com:9005/autenticar';
      //const url = "https://neocenter-energia.com/financiera/index.php";
      //const url = "https://api.escuelajs.co/api/v1/auth/login";
      const datos = {
        email,
        password,
        encript: 0,
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Tipo de contenido JSON
          // Otros encabezados según sea necesario
        },
        body: JSON.stringify(datos),
      });

      const res = await response.json();
      //      console.log("asa", res.detail.access_token);

      if (res.detail && res.detail.access_token) {
        setUserToken(res.detail.access_token);
        setItem('userToken', res.detail.access_token);

        const userProfile = await getUserInfo(res.detail.access_token);
        setUserInfo(userProfile);
        setItem('userInfo', JSON.stringify(userProfile));
      } else {
        Alert.alert(
          'Error de inicio de sesión',
          'Hubo un problema durante el inicio de sesión. Por favor, inténtalo de nuevo.'
        );
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        Alert.alert(
          'Error de inicio de sesión',
          'Credenciales incorrectas. Por favor, inténtalo de nuevo.'
        );
      } else {
        console.log(error);
        Alert.alert(
          'Error de inicio de sesión',
          'Hubo un problema durante el inicio de sesión. Por favor, inténtalo de nuevo.'
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    try {
      setIsLoading(true);
      setUserToken(null);
      await removeItem('userInfo');
      await removeItem('userToken');
    } catch (error) {
      console.error('Error al eliminar userToken:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isLoggedIn = async () => {
    try {
      setIsLoading(true);
      let userInfo = await getItem('userInfo');
      let userToken = await getItem('userToken');
      userInfo = JSON.parse(userInfo);

      if (userInfo) {
        setUserToken(userToken);
        setUserInfo(userInfo);
      }
    } catch (error) {
      console.error('Error al obtener el userToken:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <AuthContext.Provider
      value={{ login, logout, isLoading, userToken, userInfo }}
    >
      {children}
    </AuthContext.Provider>
  );
};
