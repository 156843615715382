import color from '@styles/colors';

/**
 * Función que determina el color basado en los estados de error y enfoque.
 *
 * @param {boolean} isError - Indica si hay un error.
 * @param {boolean} isFocused - Indica si el componente está enfocado.
 * @returns {string} Color correspondiente basado en las condiciones.
 */
export const getColor = (isError, isFocused) => {
	if (isError) {
		return color.alert;
	} else if (isFocused) {
		return color.brand;
	} else {
		return color.placeholder;
	}
};
