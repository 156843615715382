import "react-native-gesture-handler";
import React, { useContext } from "react";
import { NavigationContainer } from "@react-navigation/native";
import StackNavigation from "@navigation/StackNavigation";
import { AuthContext } from "@context/AuthContext";
import TabNavigation from "@navigation/TabNavigation";
import { View, ActivityIndicator } from "react-native";

const Main = () => {
  const { isLoading, userToken } = useContext(AuthContext);

  if (isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator size={"large"} />
      </View>
    );
  }
  return (
    <NavigationContainer>
      {userToken !== null ? <TabNavigation /> : <StackNavigation />}
    </NavigationContainer>
  );
};

export default Main;
