import React, { useState, useEffect, useContext } from 'react';
import colors from '@styles/colors';
import { View, TouchableOpacity, ScrollView } from 'react-native';
import { Text } from 'react-native-elements';
import color from '@styles/colors';
import MyTextInput from '@components/MyTextInput';
import MyDropdown from '@components/MyDropdown';
import FormScreen from '@screens/FormScreen';
import Error_401 from '@components/Error_401';
import { AuthContext } from '@context/AuthContext';
import DatosPersonales from '@components/DatosPersonales/';
import DateTimePicker from '@react-native-community/datetimepicker';
import useInputValue from '@hooks/useInputValue';
import MyButton from '@components/MyButton';
import {
  convertirFechaHora,
  convertirFecha,
  invertirFormatoFecha,
  obtenerDiasFaltantes,
} from '@utils/convertirFormatoFechaHora';

const DetalleClienteScreen = ({ route }) => {
  const currentDate = new Date().toLocaleString('es-PE', {
    timeZone: 'America/Lima',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const { userToken } = useContext(AuthContext);
  const [estadoOverlay, setEstadoOverlay] = useState(false);
  const { userId } = route.params;
  const [detalleProspeccion, setDetalleProspeccion] = useState([]);
  const direccionInput = useInputValue('');
  const celularInput = useInputValue('');
  const mafInput = useInputValue('');
  const [ubicacion, setUbicacion] = useState({
    departamento: 1,
    provincia: 1,
    distrito: 1,
    nombre: '',
    aPaterno: '',
    aMaterno: '',
    dni: '',
    tipificacion: 0,
    tipificacionConcepto: 0,
    categoriaProducto: 0,
    perfil: 0,
  });
  const [date, setDate] = useState(new Date());
  const [pais, setPais] = useState('1');
  const [departamentos, setDepartamentos] = useState(ubicacion.departamento);
  const [provincias, setProvincias] = useState(ubicacion.provincia);
  const [distritos, setDistritos] = useState(ubicacion.distrito);

  const handleDepartamento = (selectedValue) => {
    setProvincias('12');
    setDepartamentos(selectedValue);
  };

  const handleProvincia = (selectedValue) => {
    setProvincias(selectedValue);
  };

  const [categoria, setCategoria] = useState(ubicacion.tipificacion);
  const [tipificacion, setTipificacion] = useState(
    ubicacion.tipificacionConcepto
  );
  const [param, setParam] = useState(ubicacion.tipificacion);
  const [disabled, setDisabled] = useState(true);
  const [producto, setProducto] = useState(ubicacion.categoriaProducto);
  const [perfil, setPerfil] = useState(ubicacion.perfil);
  const handleCategoriaChange = (selectedValue) => {
    setCategoria(selectedValue);
    setDisabled(false);
    setParam(selectedValue);
  };

  const [modalidadCredito, setModalidadCredito] = useState('1');
  const [horaVisita, setHoraVisita] = useState('1');
  const [registro, setRegistro] = useState();
  const [showPicker, setShowPicker] = useState(false);

  const onChangeDate = (event, selectedDate) => {
    setShowPicker(false); // Oculta el picker al seleccionar una fecha

    if (selectedDate) {
      const currentDate = selectedDate;
      const day = String(currentDate.getDate()).padStart(2, '0');
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const year = currentDate.getFullYear();

      //const formattedDate = `${day}/${month}/${year}`;
      const formattedDate = `${year}-${month}-${day}`;

      setDate(currentDate); // Actualiza el estado de la fecha seleccionada
      setRegistro(formattedDate); // Actualiza el estado para mostrar en el botón
    }
  };

  useEffect(() => {
    actualizarDatos.dataPersonal.direccion = direccionInput.value;
    actualizarDatos.dataPersonal.celular = celularInput.value;
    actualizarDatos.dataPersonal.departamento = ubicacion.departamento;
    actualizarDatos.dataPersonal.provincia = ubicacion.provincia;
    actualizarDatos.dataPersonal.distrito = ubicacion.distrito;
    actualizarDatos.dataPersonal.nombre = ubicacion.nombre;
    actualizarDatos.dataPersonal.aPaterno = ubicacion.aPaterno;
    actualizarDatos.dataPersonal.aMaterno = ubicacion.aMaterno;
    actualizarDatos.dataPersonal.dni = ubicacion.dni;

    actualizarDatos.dataProducto.tipificacion = ubicacion.tipificacion;
    actualizarDatos.dataProducto.conceptoTipificacion =
      ubicacion.tipificacionConcepto;
    actualizarDatos.dataProducto.categoriaProducto =
      ubicacion.categoriaProducto;
    actualizarDatos.dataProducto.perfilCliente = ubicacion.perfil;
    actualizarDatos.dataProducto.maf = mafInput.value;

    //console.log(actualizarDatos.dataPersonal);
    //console.log('list', listDataProducto);
  }, [
    //Data personal
    direccionInput.value,
    celularInput.value,
    departamentos,
    provincias,
    distritos,
    ubicacion,
    //Data Producto
    tipificacion,
    categoria,
    producto,
    perfil,
    param,
    mafInput.value,
  ]);

  const actualizarDatos = {
    dataPersonal: {
      dni: '',
      nombre: '',
      aPaterno: '',
      aMaterno: '',
      celular: '',
      departamento: 0,
      provincia: 0,
      distrito: 0,
      direccion: '',
    },
    dataProducto: {
      tipificacion: 0,
      conceptoTipificacion: 0,
      categoriaProducto: 0,
      perfilCliente: 0,
      maf: 0,
    },
    dataProspeccion: {
      modalidadCredito: 0,
      fechaVisita: '',
      horaVisita: 0,
    },
  };
  const handleUpdateRegistro = async () => {
    const url = `https://apps.geatel-telecom.com:9005/actualizar/cliente/prospeccion/${userId}`;
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
          // Puedes agregar otras cabeceras según sea necesario
        },
        body: JSON.stringify(actualizarDatos), // Convierte los datos a formato JSON
      });

      if (!response.ok) {
        throw new Error(`Error en la solicitud - ${response.status}`);
      }

      // Puedes manejar la respuesta aquí si es necesario
      const responseBody = await response.json();
      //console.log('Respuesta exitosa:', responseBody);

      return responseBody; // O devuelve lo que necesites
    } catch (error) {
      console.error('Error en la solicitud:', error.message);
      // Maneja el error según tus necesidades
      throw error;
    }
  };
  useEffect(() => {
    fetchData();
  }, [userId]);

  const fetchData = async () => {
    if (estadoOverlay) return;
    try {
      const url = `https://apps.geatel-telecom.com:9005/busqueda/asesor/prospeccion/${userId}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json', // Esto puede ser necesario según las necesidades de tu API
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          setEstadoOverlay(true);
        } else {
          throw new Error(`Error de red - ${response.status}`);
        }
        return;
      }

      const responseBody = await response.json();
      setDetalleProspeccion(responseBody.detail.data);
      responseBody.detail.data.map((item) => {
        direccionInput.handleChange(item.direccion);
        celularInput.handleChange(item.celular);
        mafInput.handleChange(`${item.maf}`);
        setUbicacion({
          departamento: item.dep_id,
          provincia: item.prov_id,
          distrito: item.dis_id,
          nombre: item.nombre,
          aPaterno: item.apellido_paterno,
          aMaterno: item.apellido_materno,
          dni: item.nro_documento,
          tipificacion: item.id_tip,
          tipificacionConcepto: item.id_tipcon,
          categoriaProducto: item.id_catpro,
          perfil: item.id_pecl,
        });

        actualizarDatos.dataPersonal.dni = item.nro_documento;
        actualizarDatos.dataPersonal.nombre = item.nombre;
        actualizarDatos.dataPersonal.aPaterno = item.apellido_paterno;
        actualizarDatos.dataPersonal.aMaterno = item.apellido_materno;
        actualizarDatos.dataPersonal.direccion = item.direccion;
        actualizarDatos.dataPersonal.departamento = item.dep_id;
        actualizarDatos.dataPersonal.provincia = item.prov_id;
        actualizarDatos.dataPersonal.distrito = item.dis_id;
        actualizarDatos.dataPersonal.celular = item.celular;

        actualizarDatos.dataProducto.tipificacion = item.id_tip;
        actualizarDatos.dataProducto.tipificacionConcepto = item.id_tipcon;
        actualizarDatos.dataProducto.categoriaProducto = item.id_catpro;
        actualizarDatos.dataProducto.perfilCliente = item.id_pecl;
        actualizarDatos.dataProducto.maf = item.maf;

        actualizarDatos.dataProspeccion.modalidadCredito = item.id_modcre;
        actualizarDatos.dataProspeccion.fechaVisita = item.fecha_visita;
        actualizarDatos.dataProspeccion.horaVisita = item.hora_visita;
      });
    } catch (error) {
      let errorMessage = 'Hubo un problema al obtener los datos.';
      if (error.response) {
        errorMessage = `Error: ${error.response.status} - ${error.response.data.message}`;
      } else if (error.request) {
        errorMessage = 'No se pudo obtener respuesta del servidor.';
      } else {
        console.error('no', error);
        errorMessage = 'Error en la solicitud.';
      }
      console.error(errorMessage);
    }
  };

  return (
    <>
      {detalleProspeccion.map((item, index) => (
        <ScrollView key={index}>
          <Text
            h4
            h4Style={{
              backgroundColor: color.brand,
              textAlign: 'center',
              padding: 12,
              color: color.white,
              fontSize: 18,
              marginBottom: 24,
            }}
          >
            Fecha de registro y edición
          </Text>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              paddingLeft: 16,
              paddingRight: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <MyTextInput
                label="Fecha de registro"
                value={convertirFechaHora(item.creacion)}
                isIcon={false}
                isLeftIcon={false}
                iconLeftLibrary="material"
                iconLeftName="today"
                isDisabled={true}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              paddingLeft: 16,
              paddingRight: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <MyTextInput
                label="Fecha de edición"
                value={convertirFechaHora(item.fecha_actualizacion)}
                isIcon={false}
                isLeftIcon={false}
                iconLeftLibrary="material"
                iconLeftName="event"
                isDisabled={true}
              />
            </View>
          </View>
          <View>
            <Text
              h4
              h4Style={{
                backgroundColor: color.brand,
                textAlign: 'center',
                padding: 12,
                color: color.white,
                fontSize: 18,
                marginBottom: 24,
              }}
            >
              Datos personales
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              paddingLeft: 16,
              paddingRight: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <MyTextInput
                keyboardType="numeric"
                maxLength={8}
                label="Ingresa el DNI"
                isIcon
                isLeftIcon
                iconLeftLibrary="material"
                iconLeftName="fingerprint"
                isRightIcon={false}
                value={item.nro_documento}
                isDisabled={true}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              paddingLeft: 16,
              paddingRight: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <MyTextInput
                label="Nombre"
                placeholder="Harvey"
                isIcon
                isLeftIcon
                iconLeftLibrary="material"
                iconLeftName="badge"
                value={item.nombre}
                isDisabled={true}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              paddingLeft: 16,
              paddingRight: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <MyTextInput
                label="Apellido Paterno"
                isIcon
                isLeftIcon
                iconLeftLibrary="material"
                iconLeftName="badge"
                isDisabled={true}
                value={item.apellido_paterno}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              paddingLeft: 16,
              paddingRight: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <MyTextInput
                label="Apellido Materno"
                isIcon
                isLeftIcon
                iconLeftLibrary="material"
                iconLeftName="badge"
                isDisabled={true}
                value={item.apellido_materno}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              paddingLeft: 16,
              paddingRight: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <MyTextInput
                label="Dirección"
                isIcon
                isLeftIcon
                iconLeftLibrary="material"
                iconLeftName="place"
                value={item.direccion}
                isDisabled={true}
                {...direccionInput}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              paddingLeft: 16,
              paddingRight: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <MyDropdown
                title="Departamento"
                placeholder={item.departamento}
                endpoint={`/ubigeo/departamentos/${pais}`}
                icon="map"
                onValueChange={handleDepartamento}
                isVisibleSearch={true}
                isDisabled={true}
              />
            </View>
            <View style={{ flexGrow: 1 }}>
              <MyDropdown
                title="Provincia"
                placeholder={item.provincia}
                endpoint={`/ubigeo/provincias/${item.dep_id}`}
                icon="map"
                onValueChange={handleProvincia}
                isVisibleSearch={true}
                isDisabled={true}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              paddingLeft: 16,
              paddingRight: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <MyDropdown
                title="Distrito"
                placeholder={item.distrito}
                endpoint={`/ubigeo/distritos/${item.prov_id}`}
                icon="map"
                onValueChange={(selectedValue) => setDistritos(selectedValue)}
                isDisabled={true}
                isVisibleSearch={true}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              paddingLeft: 16,
              paddingRight: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <MyTextInput
                keyboardType="numeric"
                maxLength={9}
                minLength={9}
                label="Celular"
                isIcon
                isLeftIcon
                iconLeftLibrary="material"
                iconLeftName="phone"
                isDisabled={true}
                {...celularInput}
              />
            </View>
          </View>
          {/*PRODUCTOS */}
          <Text
            h4
            h4Style={{
              backgroundColor: color.brand,
              textAlign: 'center',
              padding: 12,
              color: color.white,
              fontSize: 18,
              marginBottom: 24,
            }}
          >
            Datos del Tipo de Producto
          </Text>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              paddingLeft: 16,
              paddingRight: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <MyDropdown
                title="Tipificación"
                placeholder={item.tipificacion}
                endpoint="/categoria/tipificacion"
                icon="document"
                onValueChange={handleCategoriaChange}
                isVisibleSearch={false}
                isDisabled={true}
              />
            </View>
            <View style={{ flexGrow: 1 }}>
              <MyDropdown
                title="Estado tipificación"
                placeholder={item.tipificacion_concepto}
                endpoint={`/categoria/tipificacion/${param || item.id_tip}`}
                icon="document-text"
                isDisabled={true}
                isVisibleSearch={true}
                onValueChange={(data) => setTipificacion(data)}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              paddingLeft: 16,
              paddingRight: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <MyDropdown
                title="Categoria de Producto"
                placeholder={item.categoria_producto}
                endpoint="/categoria/productos"
                icon="card"
                isVisibleSearch={false}
                onValueChange={(data) => setProducto(data)}
                isDisabled={true}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              paddingLeft: 16,
              paddingRight: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <MyDropdown
                title="Perfil"
                placeholder={item.perfil_cliente}
                endpoint="/categoria/perfil_cliente"
                icon="person"
                isVisibleSearch={true}
                onValueChange={(data) => {
                  setPerfil(data);
                }}
                isDisabled={true}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              paddingLeft: 16,
              paddingRight: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <MyTextInput
                keyboardType="numeric"
                label="MAF (S/.)"
                placeholder={`${item.maf}`}
                isIcon
                isLeftIcon
                iconLeftLibrary="material"
                iconLeftName="payments"
                isDisabled={true}
                {...mafInput}
              />
            </View>
          </View>
          <Text
            h4
            h4Style={{
              backgroundColor: color.brand,
              textAlign: 'center',
              padding: 12,
              color: color.white,
              fontSize: 18,
              marginBottom: 24,
            }}
          >
            Datos de la Prospección
          </Text>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              paddingLeft: 16,
              paddingRight: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <MyDropdown
                title="Modalidad de Crédito"
                placeholder={item.modalidad_credito}
                endpoint="/categoria/modalidad_credito"
                icon="cash"
                onValueChange={(selectedValued) =>
                  setModalidadCredito(selectedValued)
                }
                isVisibleSearch={false}
                isDisabled={true}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              paddingLeft: 16,
              paddingRight: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <TouchableOpacity onPress={() => setShowPicker(true)}>
                <MyTextInput
                  label="Fecha de Visita"
                  placeholder={item.fecha_visita}
                  isIcon
                  isLeftIcon
                  iconLeftLibrary="material"
                  iconLeftName="event"
                  editable={false}
                  value={registro || item.fecha_visita}
                  isDisabled={true}
                />
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              paddingLeft: 16,
              paddingRight: 16,
              marginBottom: 16,
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <MyDropdown
                title="Hora de Visita"
                placeholder={item.hora_visita}
                endpoint="/categoria/horarios_visita/normal"
                icon="time"
                onValueChange={(selectedValued) =>
                  setHoraVisita(selectedValued)
                }
                isVisibleSearch={false}
                isDisabled={true}
              />
            </View>
          </View>
          <Error_401
            setMostrarVistaCerrarSesion={setEstadoOverlay}
            mostrarVistaCerrarSesion={estadoOverlay}
          />
        </ScrollView>
      ))}
    </>
  );
};

export default DetalleClienteScreen;
