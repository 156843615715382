function agregarCero(numero) {
  return numero < 10 ? `0${numero}` : numero;
}

export function convertirFecha(fechaISO) {
  const fechaObjeto = new Date(fechaISO);

  // Ajustar a la zona horaria local
  const ajustada = new Date(
    fechaObjeto.getTime() - fechaObjeto.getTimezoneOffset() * 60000
  );

  // Obtener día, mes y año
  const dia = agregarCero(ajustada.getDate() + 1);
  const mes = agregarCero(ajustada.getMonth() + 1); // Se suma 1 porque los meses van de 0 a 11
  const año = ajustada.getFullYear();

  // Formatear la fecha
  return `${dia}/${mes}/${año}`;
}

export function convertirFechaHora(fechaISO) {
  const fechaObjeto = new Date(fechaISO);

  // Obtener día, mes y año
  const dia = agregarCero(fechaObjeto.getDate());
  const mes = agregarCero(fechaObjeto.getMonth() + 1); // Se suma 1 porque los meses van de 0 a 11
  const año = fechaObjeto.getFullYear();

  // Obtener hora, minutos y segundos
  const horas = agregarCero(fechaObjeto.getHours());
  const minutos = agregarCero(fechaObjeto.getMinutes());
  const segundos = agregarCero(fechaObjeto.getSeconds());

  // Formatear la fecha y la hora
  return `${dia}/${mes}/${año} ${horas}:${minutos}:${segundos}`;
}
export function invertirFormatoFecha(fecha) {
  const [dia, mes, año] = fecha.split('/');

  // Agregar cero delante del día y del mes si tienen solo un dígito
  const diaFormateado = agregarCero(parseInt(dia));
  const mesFormateado = agregarCero(parseInt(mes));

  return `${año}-${mesFormateado}-${diaFormateado}`;
}

export function obtenerDiasFaltantes(fechaActual, fechaVisita) {
  // Parsear las fechas en objetos Date
  const fechaActualObjeto = new Date(fechaActual);
  const fechaVisitaObjeto = new Date(fechaVisita);

  fechaActualObjeto.setHours(0, 0, 0, 0);
  fechaVisitaObjeto.setHours(0, 0, 0, 0);

  // Obtener la diferencia en milisegundos
  const diferenciaMilisegundos = fechaVisitaObjeto - fechaActualObjeto;
  // Convertir la diferencia a días
  const diasFaltantes = Math.ceil(
    diferenciaMilisegundos / (1000 * 60 * 60 * 24)
  );

  return diasFaltantes;
}
