import React, { useEffect, useState, useContext } from 'react';
import { View, TouchableOpacity } from 'react-native';
import MyTextInput from '@components/MyTextInput';
import { Text } from 'react-native-elements';
import color from '@styles/colors';
import MyDropdown from '@components/MyDropdown';
import Toast from 'react-native-toast-message';
import useInputValue from '@hooks/useInputValue';
import { getAxios } from '@utils/api';
import { Button, Overlay, Image } from 'react-native-elements';
import { Logo } from '@assets/logo.png';
import { Alert } from 'react-native-web';
import Swal from 'sweetalert2';

import { AuthContext } from '@context/AuthContext';

const DatosPersonales = ({
  onGuardarDatos,
  isDNIError,
  isNombreError,
  isAPaternoError,
  isAMaternoError,
  isAddressError,
  isPhoneError,
  isDepartamentoError,
  isProvinciaError,
  isDistritoError,
  resetState,
}) => {
  const { userToken } = useContext(AuthContext);
  const [pais, setPais] = useState('1');
  const [departamentos, setDepartamentos] = useState(0);
  const [provincias, setProvincias] = useState(0);
  const [distritos, setDistritos] = useState(0);
  const [disabledProv, setDisabledProv] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [isActiveEditable, setIsActiveEditable] = useState(false);
  const handleDepartamento = (selectedValue) => {
    setProvincias('12');
    setDepartamentos(selectedValue);
    setDisabledProv(false);
  };

  const handleProvincia = (selectedValue) => {
    setDisabled(false);
    setProvincias(selectedValue);
  };

  const [buscarDNI, setBuscarDNI] = useState('');
  const [dataUserDNI, setDataUserDNI] = useState({});
  const [visible, setVisible] = useState(false);

  const toggleOverlay = () => {
    setVisible(!visible);
  };

  const getDataDNI = async (buscarDNI) => {
    try {
      const response = await fetch(
        `https://apps.geatel-telecom.com:9005/validar/cliente_registro_asesor/${buscarDNI}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const responseBody0 = await response.json();
      const responseBody = responseBody0.detail;
      //console.log('RES', buscarDNI, responseBody);
      if (responseBody.estado === 2) {
        // Cliente no registrado
        setDataUserDNI(responseBody);
        setVisible(true);

        setTimeout(() => {
          setVisible(false);
        }, 2000);
        return true;
      } else if (responseBody.estado === 0) {
        // Cliente en base pero reservado
        setDataUserDNI(responseBody);
        setVisible(true);
        setTimeout(() => {
          setVisible(false);
        }, 2000);
        return false;
      } else if (responseBody.estado === 1) {
        //Cliente en base y disponible para gestión
        setDataUserDNI(responseBody);
        setVisible(true);
        return true;
      }

      //console.log("Datos de la respuesta:", responseBody);
    } catch (error) {
      let errorMessage = 'Hubo un problema al obtener los datos.';
      if (error.response) {
        // El servidor respondió con un código de estado diferente a 2xx
        errorMessage = `Error: ${error.response.status} - ${error.response.data.message}`;
      } else if (error.request) {
        // La petición se hizo pero no se recibió respuesta
        errorMessage = 'No se pudo obtener respuesta del servidor.';
      } else {
        // Otro tipo de errores
        console.log(error);
        errorMessage = 'Error en la solicitud.';
      }
      console.error(errorMessage);
    }
  };

  const [isUserDisabled, setIsUserDisabled] = useState(true);
  const handleSearchDNI = () => {
    if (dniInput.value.includes('.')) {
      Toast.show({
        type: 'error',
        text1: 'Por favor, comprobar el DNI',
        text2: 'Este campo no puede tener punto -> .',
      });
      return;
    }
    if (dniInput.value.length === 8) {
      setBuscarDNI(dniInput.value);

      dataUserDNI.estado === 2
        ? setIsUserDisabled(false)
        : dataUserDNI.estado === 0
        ? setIsUserDisabled(false)
        : setIsUserDisabled(true);
      setValidarDNI(false);
      //console.log('Usuario no registrado. Por favor, rellenar los campos');
      if (
        dataUserDNI.estado === 1 &&
        dataUserDNI.datos &&
        dataUserDNI.datos.length > 0
      ) {
        const userDatos = dataUserDNI.datos[0];
        nombreInput.handleChange(userDatos.nombre);
        aPaternoInput.handleChange(userDatos.apellido_paterno);
        aMaternoInput.handleChange(userDatos.apellido_materno);
        celularInput.handleChange(userDatos.celular);
        direccionInput.handleChange(userDatos.direccion);
      }
    }
    if (dataUserDNI.estado === 2) {
      // Establece los valores de los inputs en blanco
      nombreInput.handleChange('');
      aPaternoInput.handleChange('');
      aMaternoInput.handleChange('');
      celularInput.handleChange('');
      direccionInput.handleChange('');
    }
    if (dataUserDNI.estado === 0) {
      nombreInput.handleChange('');
      aPaternoInput.handleChange('');
      aMaternoInput.handleChange('');
      celularInput.handleChange('');
      direccionInput.handleChange('');
      setIsUserDisabled(true);
    }
  };

  const searchingDNI = async () => {
    if (dniInput.value.length === 8) {
      setBuscarDNI(dniInput.value);
      //searchingDNI(dniInput.value);
      /*
      nombreInput.handleChange(userDatos.nombre);
      aPaternoInput.handleChange(userDatos.apellido_paterno);
      aMaternoInput.handleChange(userDatos.apellido_materno);
      */
    } else {
      //window.alert(`Por favor, Ingrese un DNI Valido`);
      Swal.fire({
        title: 'DNI Incorrecto',
        text: 'Por favor, Ingrese un DNI Valido',
        icon: 'warning',
      });
      nombreInput.handleChange('');
      aPaternoInput.handleChange('');
      aMaternoInput.handleChange('');
      setIsActiveEditable(false);
    }
  };

  const buscarPorDNI = async (DNI) => {
    setIsActiveEditable(false);
    nombreInput.handleChange('');
    aPaternoInput.handleChange('');
    aMaternoInput.handleChange('');
    if (!DNI) return;
    try {
      const response = await fetch(
        `https://apps.geatel-telecom.com/apis/reniec/api.php?obtenerDatosReniec=1&dni=${DNI}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const res = await response.json();
      if (!res.success) {
        nombreInput.handleChange('');
        aPaternoInput.handleChange('');
        aMaternoInput.handleChange('');

        Swal.fire({
          title: 'No se ha encontrado el DNI',
          text: '¿Desea ingresarlo manualmente?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#5958fe',
          confirmButtonText: 'Sí',
        }).then((result) => {
          if (result.isConfirmed) {
            setIsActiveEditable(true);
            Swal.fire({
              title: 'Campos habilitados',
              text: 'Los campos de Nombre y Apellidos han sido habilitados para rellenar manualmente.',
              icon: 'success',
            });
          }
        });
      }
      nombreInput.handleChange(res.resultado.nombres);
      aPaternoInput.handleChange(res.resultado.apellidopaterno);
      aMaternoInput.handleChange(res.resultado.apellidomaterno);
    } catch (error) {
      console.error(
        'Error en la solicitud de verificación de autenticación:',
        error
      );
    }
  };

  useEffect(() => {
    if (buscarDNI === '00000000') return;
    buscarPorDNI(buscarDNI);
    setBuscarDNI('00000000');
  }, [buscarDNI]);

  const resetStateFunction = () => {
    dniInput.handleChange('');
    nombreInput.handleChange('');
    aPaternoInput.handleChange('');
    aMaternoInput.handleChange('');
    celularInput.handleChange('');
    direccionInput.handleChange('');
    setDepartamentos(0);
    setProvincias(0);
    setDistritos(0);
  };

  useEffect(() => {
    resetStateFunction();
  }, [resetState]);

  const dniInput = useInputValue('');
  const nombreInput = useInputValue('');
  const aPaternoInput = useInputValue('');
  const aMaternoInput = useInputValue('');
  const direccionInput = useInputValue('');
  const celularInput = useInputValue('');

  const dataPersonal = {
    dni: dniInput.value,
    nombre: nombreInput.value,
    aPaterno: aPaternoInput.value || '-',
    aMaterno: aMaternoInput.value || '-',
    celular: celularInput.value,
    departamento: departamentos,
    provincia: provincias,
    distrito: distritos,
    direccion: direccionInput.value,
  };

  useEffect(() => {
    onGuardarDatos(dataPersonal);
    //console.log(dataPersonal);
  }, [
    dniInput.value,
    nombreInput.value,
    aPaternoInput.value,
    aMaternoInput.value,
    direccionInput.value,
    departamentos,
    provincias,
    distritos,
    celularInput.value,
  ]);

  const [validarDNI, setValidarDNI] = useState(false);
  const showToast = () => {
    setValidarDNI(true);
    Toast.show({
      type: 'error',
      text1: 'Por favor, comprobar el DNI',
      text2: 'Este campo se encuentra desactivado hasta validar el DNI',
    });
  };

  return (
    <View>
      <Text
        h4
        h4Style={{
          backgroundColor: color.brand,
          textAlign: 'center',
          padding: 12,
          color: color.white,
          fontSize: 18,
          marginBottom: 24,
        }}
      >
        Datos personales
      </Text>
      <View
        style={{
          flexDirection: 'row',
          gap: 16,
          paddingLeft: 16,
          paddingRight: 16,
          marginBottom: 16,
        }}
      >
        <View style={{ flexGrow: 1 }}>
          <MyTextInput
            keyboardType="numeric"
            maxLength={8}
            label="Ingresa el DNI *"
            placeholder="76543210"
            isIcon
            isLeftIcon
            iconLeftLibrary="material"
            iconLeftName="fingerprint"
            isError={isDNIError}
            errorMessage={isDNIError ? 'Ingresa un DNI válido' : ''}
            isRightIcon={true}
            iconRightLibrary="material"
            iconRightName="search"
            onRightIconPress={searchingDNI}
            {...dniInput}
          />
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          gap: 16,
          paddingLeft: 16,
          paddingRight: 16,
          marginBottom: 16,
        }}
      >
        <View style={{ flexGrow: 1 }}>
          <MyTextInput
            label="Nombre *"
            placeholder="Harvey"
            isIcon
            isLeftIcon
            iconLeftLibrary="material"
            iconLeftName="badge"
            isError={isNombreError}
            errorMessage={isNombreError ? 'Ingresa un nombre válido' : ''}
            isDisabled={false}
            isEditable={isActiveEditable}
            {...nombreInput}
          />
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          gap: 16,
          paddingLeft: 16,
          paddingRight: 16,
          marginBottom: 16,
        }}
      >
        <View style={{ flexGrow: 1 }}>
          <MyTextInput
            label="Apellido Paterno *"
            placeholder="Reginald"
            isIcon
            isLeftIcon
            iconLeftLibrary="material"
            iconLeftName="badge"
            isError={isAPaternoError}
            errorMessage={isAPaternoError ? 'Ingresa un apellido válido' : ''}
            isDisabled={false}
            isEditable={isActiveEditable}
            {...aPaternoInput}
          />
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          gap: 16,
          paddingLeft: 16,
          paddingRight: 16,
          marginBottom: 16,
        }}
      >
        <View style={{ flexGrow: 1 }}>
          <MyTextInput
            label="Apellido Materno *"
            placeholder="Specter"
            isIcon
            isLeftIcon
            iconLeftLibrary="material"
            iconLeftName="badge"
            isError={isAMaternoError}
            errorMessage={isAMaternoError ? 'Ingresa un apellido válido' : ''}
            isDisabled={false}
            isEditable={isActiveEditable}
            {...aMaternoInput}
          />
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          gap: 16,
          paddingLeft: 16,
          paddingRight: 16,
          marginBottom: 16,
        }}
      >
        <View style={{ flexGrow: 1 }}>
          <MyTextInput
            label="Dirección"
            placeholder="P. Sherman Calle Wallaby 42, Sidney"
            isIcon
            isLeftIcon
            iconLeftLibrary="material"
            iconLeftName="place"
            {...direccionInput}
          />
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          gap: 16,
          paddingLeft: 16,
          paddingRight: 16,
          marginBottom: 16,
        }}
      >
        <View style={{ flexGrow: 1 }}>
          <MyDropdown
            title="Departamento"
            placeholder="Selecciona..."
            endpoint={`/ubigeo/departamentos/${pais}`}
            icon="map"
            onValueChange={handleDepartamento}
            isError={isDepartamentoError}
            errorMessage={
              isDepartamentoError ? 'Selecciona el departamento' : ''
            }
            isVisibleSearch={true}
          />
        </View>
        <View style={{ flexGrow: 1 }}>
          <MyDropdown
            title="Provincia"
            placeholder="Selecciona..."
            endpoint={`/ubigeo/provincias/${departamentos}`}
            icon="map"
            onValueChange={handleProvincia}
            isDisabled={disabledProv}
            isError={isProvinciaError}
            errorMessage={isProvinciaError ? 'Selecciona la provincia' : ''}
            isVisibleSearch={true}
          />
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          gap: 16,
          paddingLeft: 16,
          paddingRight: 16,
          marginBottom: 16,
          flexWrap: 'wrap',
        }}
      >
        <View style={{ flexGrow: 1 }}>
          <MyDropdown
            title="Distrito"
            placeholder="Selecciona.."
            endpoint={`/ubigeo/distritos/${provincias}`}
            icon="map"
            onValueChange={(selectedValue) => setDistritos(selectedValue)}
            isDisabled={disabled}
            isError={isDistritoError}
            errorMessage={isDistritoError ? 'Selecciona el distrito' : ''}
            isVisibleSearch={true}
          />
        </View>
        <View style={{ flexGrow: 1 }}>
          <MyTextInput
            keyboardType="numeric"
            maxLength={9}
            label="Celular *"
            placeholder="987654321"
            isIcon
            isLeftIcon
            iconLeftLibrary="material"
            iconLeftName="phone"
            isError={isPhoneError}
            errorMessage={isPhoneError ? 'Ingresa un número válido' : ''}
            {...celularInput}
          />
        </View>
      </View>
      <Overlay
        isVisible={visible}
        onBackdropPress={toggleOverlay}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            source={{
              uri:
                dataUserDNI.estado === 0
                  ? 'https://cdn-icons-png.flaticon.com/512/564/564619.png'
                  : 'https://static-00.iconduck.com/assets.00/success-icon-512x512-qdg1isa0.png',
            }}
            style={{
              height: 100,
              width: 100,
              marginBottom: 16,
            }}
          />
        </View>
        <Text
          h3
          h3Style={{
            marginBottom: 8,
            color: dataUserDNI.estado === 0 ? color.alert : color.success,
            textAlign: 'center',
          }}
        >
          {dataUserDNI.estado === 0
            ? 'Cliente Reservado'
            : dataUserDNI.estado === 1
            ? 'Cliente Disponible'
            : dataUserDNI.estado === 2
            ? 'Cliente No Registrado'
            : ''}
        </Text>
        <Text style={{ textAlign: 'center', fontSize: 18 }}>
          {dataUserDNI.msg}
        </Text>
      </Overlay>
    </View>
  );
};

export default DatosPersonales;
