import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Text } from 'react-native-elements';
import color from '@styles/colors';
import SIN_CONEXION from '@/assets/image/No_connection.gif';

const NoInternetScreen = () => {
	return (
		<SafeAreaView style={styles.container}>
			<View style={styles.contentContainer}>
				<Image source={SIN_CONEXION} style={styles.image} />
				<Text h1 h1Style={styles.heading}>
					Ooops!
				</Text>
				<Text h4 h4Style={styles.subHeading}>
					Conexión lenta o sin internet
				</Text>
				<Text style={styles.text}>Revisa tu configuración de internet</Text>
			</View>
		</SafeAreaView>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	contentContainer: {
		alignItems: 'center',
	},
	image: {
		resizeMode: 'contain',
		maxWidth: '100%',
		maxHeight: '100%',
	},
	heading: {
		color: color.brand,
		marginBottom: 8,
	},
	subHeading: {
		marginBottom: 4,
	},
	text: {
		fontSize: 16,
	},
});

export default NoInternetScreen;
