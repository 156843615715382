import React from 'react';
import { Input, Icon } from 'react-native-elements';
import useInputFocus from '@hooks/useInputFocus';
import color from '@styles/colors';
import PropTypes from 'prop-types';
import { getColor } from '@utils/getColor';

/**
 * Componente de Input personalizado que incluye la lógica para renderizar iconos
 * izquierdo y derecho basado en ciertas condiciones.
 * @param {string} label - Texto del label del input.
 * @param {string} placeholder - Placeholder del input.
 * @param {boolean} isIcon - Indica si se debe mostrar el icono.
 * @param {boolean} isLeftIcon - Indica si se debe mostrar el icono izquierdo.
 * @param {string} iconLeftLibrary - Biblioteca del icono izquierdo a mostrar.
 * @param {string} iconLeftName - Nombre del icono izquierdo a mostrar.
 * @param {boolean} isRightIcon - Indica si se debe mostrar el icono derecho.
 * @param {string} iconRightLibrary - Biblioteca del icono derecho a mostrar.
 * @param {string} iconRightName - Nombre del icono derecho a mostrar.
 * @param {function} onRightIconPress - Función a ejecutar al presionar el icono derecho.
 * @param {boolean} isError - Indica si se debe mostrar el mensaje de error.
 * @param {string} errorMessage - Mensaje de error.
 * @returns {JSX.Element} Componente de Input personalizado.
 */
const MyTextInput = ({
  label,
  placeholder,
  isIcon,
  isLeftIcon,
  iconLeftLibrary,
  iconLeftName,
  isError,
  errorMessage,
  isRightIcon,
  iconRightLibrary,
  iconRightName,
  onRightIconPress,
  isDisabled = false,
  isEditable = true,
  ...inputProps
}) => {
  const { isFocused, handleFocus, handleBlur } = useInputFocus();

  /**
   * Función para renderizar el icono izquierdo del input.
   * @returns {JSX.Element | null} Elemento representando el icono izquierdo o null si no se debe renderizar.
   */
  const renderLeftIcon = () => {
    if (isIcon && isLeftIcon) {
      return (
        <Icon
          type={iconLeftLibrary}
          name={iconLeftName}
          color={getColor(isError, isFocused)}
        />
      );
    }
  };
  /**
   * Función para renderizar el icono derecho del input.
   * @returns {JSX.Element | null} Elemento representando el icono derecho o null si no se debe renderizar.
   */
  const renderRightIcon = () => {
    if (isIcon && isRightIcon) {
      return (
        <Icon
          type={iconRightLibrary}
          name={iconRightName}
          color={getColor(isError, isFocused)}
          onPress={onRightIconPress}
          iconStyle={{ padding: 6, borderRadius: 50 }}
        />
      );
    }
    return null;
  };

  return (
    <Input
      containerStyle={{
        width: '100%',
        paddingHorizontal: 0,
      }}
      label={label}
      labelStyle={{
        marginBottom: 8,
        color: getColor(isError, isFocused),
      }}
      inputStyle={{ fontSize: 16 }}
      placeholder={placeholder}
      leftIcon={renderLeftIcon()}
      rightIcon={renderRightIcon()}
      inputContainerStyle={{
        borderWidth: isFocused ? 2 : 1,
        borderBottomWidth: isFocused ? 2 : 1,
        borderRadius: 10,
        borderColor: getColor(isError, isFocused),
        paddingLeft: 16,
        paddingRight: 16,
        gap: 8,
        height: 48,
        justifyContent: 'space-between',
        paddingRight: 0,
      }}
      renderErrorMessage={isError}
      errorMessage={errorMessage}
      errorStyle={{ color: color.alert, fontSize: 14 }}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChangeText={inputProps.handleChange}
      value={inputProps.value}
      {...inputProps}
      disabled={isDisabled}
      editable={isEditable}
    />
  );
};

MyTextInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isIcon: PropTypes.bool.isRequired,
  isLeftIcon: PropTypes.bool,
  iconLeftLibrary: PropTypes.string,
  iconLeftName: PropTypes.string,
  isRightIcon: PropTypes.bool,
  iconRightLibrary: PropTypes.string,
  iconRightName: PropTypes.string,
  onRightIconPress: PropTypes.func,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

MyTextInput.defaultProps = {
  placeholder: '',
  isLeftIcon: false,
  iconLeftLibrary: null,
  iconLeftName: null,
  isRightIcon: false,
  iconRightLibrary: null,
  iconRightName: null,
  onRightIconPress: null,
  isError: false,
  errorMessage: '',
};

export default MyTextInput;
