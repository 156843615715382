import { getAxios } from '@/utils/api';
import { BASE_URL } from '@/const';

/**
 * Realiza una solicitud para obtener una lista de usuarios desde una API mediante el método GET.
 * Permite la paginación utilizando el parámetro `offset` para indicar el punto de inicio de los resultados.
 *
 * @param {number} offset - Número que indica el punto de inicio de los resultados. Cada página contendrá 20 elementos.
 * @param {string} accessToken - Token de acceso del usuario para autenticar la solicitud (opcional).
 * @returns {Promise<object[]>} - Una promesa que resuelve en un array de objetos con la información de los usuarios.
 * @throws {Error} - Se lanza en caso de problemas durante la solicitud a la API.
 *
 */
export const getAllUsers = async (offset, accessToken) => {
	const allUsersUrl = `https://api.escuelajs.co/api/v1/products?limit=20&offset=${offset}`;

	const profileRequestOptions = {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	};

	try {
		const response = await getAxios(allUsersUrl, profileRequestOptions);
		return response.data;
	} catch (error) {
		throw error;
	}
};
