/**
 * Función para validar un correo electrónico.
 * @param {string} email - Correo electrónico a validar.
 * @returns {boolean} - true si el correo electrónico es válido, false de lo contrario.
 */
export const isValidEmail = (email) => {
  // Implementa tu lógica de validación de correo electrónico aquí
  // Puedes usar una expresión regular o cualquier otro método que prefieras
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

/**
 * Función para validar una contraseña.
 * @param {string} password - Contraseña a validar.
 * @returns {boolean} - true si la contraseña es válida, false de lo contrario.
 */
export const isValidPassword = (password) => {
  // Implementa tu lógica de validación de contraseña aquí
  // Por ejemplo, puedes verificar si tiene al menos 6 caracteres
  return password.length >= 6;
};

/**
 * Verifica si un número de teléfono es válido, conteniendo exactamente 9 dígitos y siendo solo numérico.
 * @param {string} phoneNumber - El número de teléfono a validar.
 * @returns {boolean} - Devuelve true si el número de teléfono es válido (exactamente 9 dígitos y solo numérico), de lo contrario, devuelve false.
 */
export function isValidPhoneNumber(phoneNumber) {
  // Expresión regular para validar un número de teléfono con exactamente 9 dígitos
  const regexPhone = /^\d{9}$/;

  // Verificar si phoneNumber cumple con la expresión regular
  return regexPhone.test(phoneNumber);
}

/**
 * Verifica si un nombre de persona es válido, no debe contener espacios vacíos y solo permitir letras y caracteres especiales comunes en nombres.
 * @param {string} name - El nombre de persona a validar.
 * @returns {boolean} - Devuelve true si el nombre de persona es válido (no tiene espacios vacíos y contiene solo letras y caracteres especiales comunes), de lo contrario, devuelve false.
 */
export function isValidPersonName(name) {
  // Expresión regular para validar un nombre de persona que permite letras, espacios y caracteres especiales comunes
  const regexPersonName = /^[A-Za-zÁáÉéÍíÓóÚúÑñÜü\s'.-]+$/;

  // Verificar si name cumple con la expresión regular
  return regexPersonName.test(name);
}

export function isValidAddress(address) {
  const regexAddress = /^(?=.*[a-zA-Z0-9\s.,#ÁáÉéÍíÓóÚúÑñÜü'-]).+$/;
  return regexAddress.test(address);
}

/**
 * Verifica si un número de DNI peruano es válido, debe contener exactamente 8 dígitos numéricos.
 * @param {string} dni - El número de DNI a validar.
 * @returns {boolean} - Devuelve true si el número de DNI es válido (consta de exactamente 8 dígitos numéricos), de lo contrario, devuelve false.
 */
export function isValidPeruvianDNI(dni) {
  // Expresión regular para validar un número de DNI peruano con exactamente 8 dígitos
  const regexPeruvianDNI = /^\d{8}$/;

  // Verificar si dni cumple con la expresión regular
  return regexPeruvianDNI.test(dni);
}
