import { postAxiosa } from '@utils/api';
import { BASE_URL } from '@/const';

/**
 * Obtiene la información del perfil del usuario mediante una solicitud a la API.
 *
 * @param {string} accessToken - Token de acceso del usuario.
 * @returns {Promise<object>} - Objeto que contiene la información del perfil del usuario.
 * @throws {Error} - Se lanza en caso de errores durante la solicitud o si no se reciben datos válidos.
 *
 */
export const getUserInfo = async accessToken => {
	//const profileUrl = `${BASE_URL}/api/v1/auth/profile`;
	const profileUrl = `${BASE_URL}/autenticar/perfil`;

	const profileRequestOptions = {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	};

	try {
		const profileResponse = await postAxiosa(profileUrl, accessToken);
		// Verifica si la respuesta contiene datos válidos
		if (profileResponse.detail.data) {
			const userProfile = profileResponse.detail.data;

			return userProfile;
		} else {
			throw new Error('Error al obtener el perfil del usuario');
		}
	} catch (error) {
		throw error;
	}
};
